import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
@Component({
  selector: 'app-other-files',
  templateUrl: './other-files.component.html',
  styleUrls: ['./other-files.component.scss'],
})
export class OtherFilesComponent {
  @Input() interviewID: string;

  public fileType = 'other';
  public isSubmitting = false;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
  ) { }

  get userType() {
    return this.authService.getUserType();
  }

  submit() {
    this.isSubmitting = true;
    this.backendService.submit(this.interviewID).subscribe(data => {
      this.isSubmitting = false;
      if (data && data.error) {
        this.toastr.error(data.error);
      } else {
        this.toastr.success('Interview submitted successfully');
      }
    });
  }

  archive() {
    this.isSubmitting = true;
    this.backendService.archive(this.interviewID).subscribe(data => {
      this.isSubmitting = false;
      if (data && data.error) {
        this.toastr.error(data.error);
      } else {
        this.toastr.success('Interview archived successfully');
      }
    });
  }
}
