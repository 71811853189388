<div class="form-title mt-3 mb-3">
  Archivist Contact Information
</div>
<div class="form-data" [formGroup]="archivistContactInformationForm">
    <div class="form-group">
      <label for="archivistsNames" class="required">Archivist(s) Name(s)</label>
      <input type="text" class="form-input" id="archivistsNames" formControlName="archivistsNames" required>
    </div>
    <div class="form-group">
      <label for="emailArchivist" class="required">Archivist Email</label>
      <input type="emailArchivist" class="form-input" id="emailArchivist" formControlName="emailArchivist" required>
    </div>
    <div class="form-group">
      <label for="facebookURL">Archivist Facebook URL</label>
      <input type="text" class="form-input" id="facebookURL" formControlName="facebookURL">
    </div>
    <div class="form-group">
      <label for="archivistTwitterHandle">Archivist Twitter Handle</label>
      <input type="text" class="form-input" id="twitterHandle"  formControlName="twitterHandle">
    </div>
    <div class="form-group">
      <label for="archivistInstagramHandle">Archivist Instagram Handle</label>
      <input type="text" class="form-input" id="instagramHandle" formControlName="instagramHandle">
    </div>
    <div class="form-group">
      <label for="archivistLinkedInProfile">Archivist LinkedIn Profile</label>
      <input type="text" class="form-input" id="linkedInProfile" formControlName="linkedInProfile">
    </div>
    <div class="form-group">
      <label for="archivistTikTokProfile">Archivist TikTok Profile</label>
      <input type="text" class="form-input" id="tikTokProfile" formControlName="tikTokProfile">
    </div>
    <div class="mt-5 text-center">
      <button class="save-button" (click)="saveForm()">Save</button>
    </div>
</div>
<div class="form-title mt-3 mb-3">
  Auditor Contact Information
</div>
<div class="form-data" [formGroup]="auditorContactInformationForm">
  <div class="form-group">
    <label for="auditorName" class="required">Auditor Name</label>
    <input type="text" class="form-input" id="auditorName" formControlName="auditorName">
  </div>
  <div class="form-group">
    <label for="auditorEmails" class="required">Auditor Email(s)</label>
    <input type="auditorEmails" class="form-input" id="auditorEmails" formControlName="auditorEmails">
  </div>
  <div class="mt-5 text-center">
      <button class="save-button" (click)="saveAuditorContactInformationForm()">Save</button>
  </div>
</div>