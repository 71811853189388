import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-submission-layout',
  templateUrl: './submission-layout.component.html',
  styleUrls: ['./submission-layout.component.scss']
})
export class SubmissionLayoutComponent implements OnInit {

  @Input() currentSubmissionStep: string;
  @Input() currentArchivistStep: string;
  @Input() context: string;
  @Output() contextChanged = new EventEmitter<string>();
  @Output() submissionStep = new EventEmitter<string>();
  @Output() archivistStep = new EventEmitter<string>();

  public interviewID;
  public interviewType: string;
  public intervieweeName: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private backendService: BackendService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      const id = params.get('id');
      const tId = params.get('tId');
      const paId = params.get('paId');

      if (id) {
        this.interviewType = '';
      } else if (paId) {
        this.interviewType = 'PA';
      } else if (tId) {
        this.interviewType = 'T';
      }

      let temporaryInterviewId = tId || paId;
      this.interviewID = tId || paId || id;

      if (!tId && !paId && id) {
        // fetch temporary interviewId from backend
        const result = await this.backendService.getInterview(+id).toPromise();
        temporaryInterviewId = result.interviewID;
      }

      // Get interviewee name
      if (temporaryInterviewId) {
        this.backendService.getInterviewee(temporaryInterviewId).subscribe(interviewee => {
          if (interviewee) {
            this.intervieweeName = interviewee.fullName
          }
        });
      }
    });
  }

  get userType() {
    return this.authService.getUserType();
  }

  changeContext(context: string): void {
    this.contextChanged.emit(context);
  }

  changeSubmissionStep(step: string) {
    this.currentSubmissionStep = step;
    this.submissionStep.emit(step);
  }

  changeArchivistStep(step: string) {
    this.currentArchivistStep = step;
    this.archivistStep.emit(step);
  }

  toDashboard() {
    this.router.navigateByUrl('/');
  }
}
