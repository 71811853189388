import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-audio-video-files',
  templateUrl: './audio-video-files.component.html',
  styleUrls: ['./audio-video-files.component.scss'],
})
export class AudioVideoFilesComponent {
  @Input() interviewID: string;

  public fileType = "audio-video";

  constructor(
    private authService: AuthService
  ) { }

  get userType() {
    return this.authService.getUserType();
  }
}
