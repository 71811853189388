import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  public registerForm: FormGroup;
  public showForgotPasswordForm = false;
  public forgotEmail = '';
  public isRegistered = false;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private toastr: ToastrService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.registerForm = this.fb.group({
      email: [null, Validators.required ],
      fullName: [null, Validators.required ],
      password: [null, Validators.required ]
    });
  }

  onSubmit() {
    const email = this.registerForm.controls['email'].value;
    const fullName = this.registerForm.controls['fullName'].value;
    const password = this.registerForm.controls['password'].value;
    this.authService.register(email, fullName, password).subscribe((response) => {
      if (response.code == 'auth/success') {
        this.isRegistered = true;
      } else {
        this.toastr.error(response.error, 'Sign Up Error');
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}