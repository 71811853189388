import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private endpoint = environment.api_url;

  private headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  getRemoteURLBlob<T>(url: string): Observable<T> {
    return this.handleResponse(this.httpClient.get(url, { responseType: 'blob' }));
  }

  getBlob<T>(path: string): Observable<T> {
    return this.handleResponse(this.httpClient.get(this.endpoint + path, { responseType: 'blob' }));
  }

  get<T>(path: string, params?: any): Observable<T> {
    return this.handleResponse(this.httpClient.get<T>(this.endpoint + path, { ...this.headers, params }));
  }

  post<T>(path, data: T): Observable<T> {
    return this.handleResponse(this.httpClient.post<T>(this.endpoint + path, data, this.headers));
  }

  postForm<T>(path, data: T): Observable<T> {
    return this.handleResponse(this.httpClient.post<T>(this.endpoint + path, data));
  }

  put<T>(path, data: T): Observable<T> {
    return this.handleResponse(this.httpClient.put<T>(this.endpoint + path, data, this.headers));
  }

  delete<T>(path, data = null): Observable<T> {
    return this.handleResponse(this.httpClient.request<T>('DELETE', this.endpoint + path, { ...this.headers, body: data }));
  }

  handleResponse(call) {
    return call.pipe(tap(
      (data) => { 
        //console.log('api service:data', data) 
      },
      (data) => { 
        if (data.error.code === 'auth/id-token-expired') {
          this.toastr.error('Auth', 'Token expired, please login again.');
          localStorage.removeItem('1947_token');
          this.router.navigateByUrl('/login');
        }
      },
    ),);
  }
}
