import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userType: 'archivist' | 'interviewer' | 'admin';

  constructor(public apiService: ApiService,
              public router: Router) { }

  login(email: string, password: string): Observable<any> {
    return this.apiService.post<any>('/api/login', { email, password }).pipe(
      tap( response => {
        if (response.code == 'auth/success') {
          this.userType = response.role;
          this.setUserType(this.userType);
          this.setToken(response.JWT);
          this.setRefreshToken(response.refresh);
          this.router.navigateByUrl('/');
        }
      })
    );
  }

  register(email: string, fullName: string, password: string): Observable<any>{
    return this.apiService.post<any>('/api/signup', { email, fullName, password });
  }

  public refreshToken(refreshToken: string) {
    return this.apiService.post<any>('/api/refresh', { refreshToken }).pipe(
        tap((response: any) => {
          if (response.code == 'auth/success') {
              this.setToken(response.JWT);
              this.setRefreshToken(response.refresh);
            }
        }),
        catchError(err => of([]))
    );
  }

  getUserType(): string {
    return  localStorage.getItem('1947_user_type');
  }

  setUserType(userType: string): void {
    localStorage.setItem('1947_user_type', userType);
  }

  setToken(token: string): void {
    localStorage.setItem('1947_token', token);
  }

  setRefreshToken(refresh: string): void {
    localStorage.setItem('refresh', refresh);
  }

  getToken(): string {
    return localStorage.getItem('1947_token');
  }

  isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  logout() {
    localStorage.removeItem('1947_token');
  }
}
