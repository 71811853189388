import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-submission-form',
  templateUrl: './submission-form.component.html',
  styleUrls: ['./submission-form.component.scss']
})
export class SubmissionFormComponent implements OnInit {

  public currentSubmissionStep = 1;
  public currentArchivistStep = 1;
  public context = 'submission';
  public interviewID;
  public finishedLoading = false;

  constructor(
    public route: ActivatedRoute,
    private backendService: BackendService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      const tId = params.get('tId');
      const paId = params.get('paId');

      this.interviewID = tId || paId;

      if (!this.interviewID && id) {
        // fetch temporary interviewId from backend
        this.backendService.getInterview(+id).subscribe(result => {
          this.interviewID = result.interviewID;
          this.finishedLoading = true;
        });
      } else {
        this.finishedLoading = true;
      }

      if (!id && !tId && !paId) {
        // Create an interview
        this.backendService.createInterview().subscribe(result => {
          this.router.navigate(['submission-form/t', result.interviewId]);
          this.finishedLoading = true;
        });
      } else {
        this.finishedLoading = true;
      }
    });
  }

  nextStep() {
    if (this.context == 'submission') {
      this.currentSubmissionStep++;
    } else {
      this.currentArchivistStep++;
    }
  }

  changeContext(context) {
    this.context = context;
  }

  changeSubmissionStep(step) {
    this.currentSubmissionStep = step;
  }

  changeArchivistStep(step) {
    this.currentArchivistStep = step;
  }
}
