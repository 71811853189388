import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-residence-before-partition',
  templateUrl: './residence-before-partition.component.html',
  styleUrls: ['./residence-before-partition.component.scss']
})
export class ResidenceBeforePartitionComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public residenceBeforePartitionForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.residenceBeforePartitionForm = this.fb.group({
      houseNumberBeforePartition: [null],
      streetBeforePartition: [null],
      cityBeforePartition: [null ],
      stateBeforePartition: [null ],
      districtBeforePartition: [null],
      postalCodeBeforePartition: [null],
      countryBeforePartition: [null ],
      villageBeforePartition: [null],
      latitude1: [null ],
      latitude2: [null],
      longitude1: [null ],
      longitude2: [null],
      migratedFromAddress: [null ]
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getResidenceBeforePartition(this.interviewID).subscribe(residence => {
        if (residence) {
          this.residenceBeforePartitionForm = this.fb.group({
            houseNumberBeforePartition: [ residence.houseNumberBeforePartition ],
            streetBeforePartition: [ residence.streetBeforePartition ],
            cityBeforePartition: [ residence.cityBeforePartition ],
            stateBeforePartition: [ residence.stateBeforePartition ],
            districtBeforePartition: [ residence.districtBeforePartition ],
            postalCodeBeforePartition: [ residence.postalCodeBeforePartition ],
            countryBeforePartition: [ residence.countryBeforePartition ],
            villageBeforePartition: [ residence.villageBeforePartition ],
            latitude1: [ residence.latitude1 ],
            longitude1: [ residence.longitude1 ],
            latitude2: [ residence.latitude2 ],
            longitude2: [ residence.longitude2 ],
            migratedFromAddress: [residence.migratedFromAddress ]
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.residenceBeforePartitionForm.controls['city'].setValidators(Validators.required);
      this.residenceBeforePartitionForm.controls['city'].updateValueAndValidity();
      this.residenceBeforePartitionForm.controls['state'].setValidators(Validators.required);
      this.residenceBeforePartitionForm.controls['state'].updateValueAndValidity();
      this.residenceBeforePartitionForm.controls['country'].setValidators(Validators.required);
      this.residenceBeforePartitionForm.controls['country'].updateValueAndValidity();
      this.residenceBeforePartitionForm.controls['latitude1'].setValidators(Validators.required);
      this.residenceBeforePartitionForm.controls['latitude1'].updateValueAndValidity();
      this.residenceBeforePartitionForm.controls['longitude1'].setValidators(Validators.required);
      this.residenceBeforePartitionForm.controls['longitude1'].updateValueAndValidity();
      this.residenceBeforePartitionForm.controls['migratedFromAddress'].setValidators(Validators.required);
      this.residenceBeforePartitionForm.controls['migratedFromAddress'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.residenceBeforePartitionForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const houseNumberBeforePartition = this.residenceBeforePartitionForm.controls['houseNumberBeforePartition'].value;
    const streetBeforePartition = this.residenceBeforePartitionForm.controls['streetBeforePartition'].value;
    const cityBeforePartition = this.residenceBeforePartitionForm.controls['cityBeforePartition'].value;
    const stateBeforePartition = this.residenceBeforePartitionForm.controls['stateBeforePartition'].value;
    const districtBeforePartition = this.residenceBeforePartitionForm.controls['districtBeforePartition'].value;
    const postalCodeBeforePartition = this.residenceBeforePartitionForm.controls['postalCodeBeforePartition'].value;
    const countryBeforePartition = this.residenceBeforePartitionForm.controls['countryBeforePartition'].value;
    const villageBeforePartition = this.residenceBeforePartitionForm.controls['villageBeforePartition'].value;
    const latitude1 = this.residenceBeforePartitionForm.controls['latitude1'].value;
    const longitude1 = this.residenceBeforePartitionForm.controls['longitude1'].value;
    const latitude2 = this.residenceBeforePartitionForm.controls['latitude2'].value;
    const longitude2 = this.residenceBeforePartitionForm.controls['longitude2'].value;
    const migratedFromAddress = this.residenceBeforePartitionForm.controls['migratedFromAddress'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      houseNumberBeforePartition,
      streetBeforePartition,
      cityBeforePartition,
      stateBeforePartition,
      districtBeforePartition,
      postalCodeBeforePartition,
      countryBeforePartition,
      villageBeforePartition,
      latitude1,
      longitude1,
      latitude2,
      longitude2,
      migratedFromAddress,
    };
    this.backendService.saveResidenceBeforePartition(data)
      .subscribe(() => this.toastr.success('Interviewee Residence Before Partition', 'Data saved successfully'))
    this.formSaved.emit();
  }

  get userType() {
    return this.authService.getUserType();
  }

}
