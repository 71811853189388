
<div class="search-box">
    <input name="searchText" class="search-input" placeholder="Search..." (keyup.enter)="search()" [(ngModel)]="searchText">
    <button class="search-button" (click)="search()">Search</button>
</div>
<div *ngIf="!isLoading" class="result-count">
    <label>{{ tableData.length }} result{{ tableData.length === 1 ? '' : 's'}}. Showing page {{ currentPage + 1 }} of {{ totalPages }}.</label>
</div>
<div class="table-container row" style="min-height:600px">
    <div class="filter-box col-sm-2">
        <label class="filters-title">Filter By</label>
        <label class="filters-secondary-title">Status</label>
        <div class="filters-container">
            <div class="filter">
                <label class="checkbox-label" for="allStatus">
                    <input type="checkbox" id="allStatus" (click)="selectAllStatus()" [checked]="allStatusSelected" /> All
                </label>
            </div>
            <div class="filter" *ngFor="let status of facets.statuses; let i = index">
                <label class="checkbox-label">
                    <input type="checkbox" [checked]="selectedStatuses.indexOf(status) > -1" value="status" (change)="selectStatus($event, status)" />
                    {{status}}
                </label>
            </div>
        </div>
        <ng-container *ngIf="userType !== 'interviewer'">
            <label class="filters-secondary-title">Priority</label>
            <div class="filters-container">
                <div class="filter">
                    <label class="checkbox-label" for="allPriority">
                        <input type="checkbox" id="allPriority" (click)="selectAllPriorities()" [checked]="allPrioritiesSelected" /> All
                    </label>
                </div>
                <div class="filter" *ngFor="let priority of facets.priorities; let i = index">
                    <label class="checkbox-label">
                        <input type="checkbox" [checked]="selectedPriorities.indexOf(priority) > -1" value="priority"
                            (change)="selectPriority($event, priority)" />
                        {{priority}}
                    </label>
                </div>
            </div>
            <ng-container *ngIf="userType !== 'archivist'">
                <label class="filters-secondary-title">Assigned To</label>
                <div class="filters-container">
                    <div class="filter">
                        <label class="checkbox-label" for="allAssigments">
                            <input type="checkbox" id="allAssigments" (click)="selectAllAssignments()" [checked]="allAssignmentsSelected" /> All
                        </label>
                    </div>
                    <div class="filter" *ngFor="let assignment of facets.assignments; let i = index">
                        <label class="checkbox-label">
                            <input type="checkbox" [checked]="selectedAssignments.indexOf(assignment) > -1" value="assignment"
                                (change)="selectAssignment($event, assignment)" />
                            {{assignment}}
                        </label>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="table col-9">
        <app-records-table [data]="tableData" [columns]="tableColumns" [currentPage]="currentPage" [isLoading]="isLoading"></app-records-table>
        <div *ngIf="userType === 'admin'" class="float-right d-flex mr-4">
            <div *ngIf="!isStoryMapDownloadLoading" class="mr-4" style="cursor:pointer" (click)="downloadStoryMap()">
                CartoDB Download
                <img class="pointer" src="../../../assets/images/download-button.png">
            </div>
            <div *ngIf="isDownloadLoading || isStoryMapDownloadLoading">
                <img src="https://miro.medium.com/max/441/1*9EBHIOzhE1XfMYoKz1JcsQ.gif" style="height:50px;margin:-50px 0">
            </div>
            <div *ngIf="!isDownloadLoading" style="cursor:pointer" (click)="downloadFile()">
                Download
                <img class="pointer" src="../../../assets/images/download-button.png">
            </div>
        </div>
    </div>
</div>
<div class="d-flex mx-auto paginator" *ngIf="totalCount > perPage">
    <div *ngIf="currentPage > 0" class="mr-3 pointer" (click)="changePage(currentPage - 1)">
        < Previous
    </div>
    <!--<div *ngFor="let index of pageIndices" class="px-2 pointer"
        [ngClass]="{'page-selected': currentPage === index }" (click)="changePage(index)">
        {{ index + 1 }}
    </div>-->
    <div class="px-2 pointer page-selected">
        {{ currentPage + 1 }}
    </div>
    <div *ngIf="currentPage < totalPages - 1" class="ml-3 pointer" (click)="changePage(currentPage + 1)">
        Next >
    </div>
</div>
