<div class="header-container">
    <img class="header-logo pointer" src="../../../assets/images/header-logo.png" routerLink="/">
<label _ngcontent-jhu-c10="" routerlink="/submission-form" class="header-button mr-4" tabindex="0" style="
    /* padding-top: 9px; */
    position: relative;
    display: inline;
    top: 8px;
    right: -10px;
">The 1947 Partition Archive</label>
    <div class="float-right header-buttons-container">
        <label class="header-button mr-4" routerLink="/submission-form">New Interview</label>
        <label class="header-button mr-4" routerLink="/">Dashboard</label>
        <label *ngIf="isLoggedIn" (click)="logout()" class="header-button ml-4">Logout</label>
        <label *ngIf="!isLoggedIn"  routerLink="/login" class="header-button ml-4">Login</label>
    </div>
</div>
