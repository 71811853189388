<table class="table table-container table-striped">
    <tr class="table-header d-inline-flex">
        <th class="column-header d-inline-flex align-items-start mr-3" *ngFor="let column of columns; index as i" width="{{ widths[i] }}">
            <label class="column-title">{{ column.name }}</label>
            <img (click)="sort(column.sortColumn)" class="pointer ml-2" *ngIf="column.sortable" src="../../../assets/images/sort-button.png">
        </th>
    </tr>
    <div *ngIf="isLoading" style="text-align:center">
        <img src="https://miro.medium.com/max/441/1*9EBHIOzhE1XfMYoKz1JcsQ.gif" style="height:100px;margin:200px 0">
    </div>
    <tbody>
        <tr class="table-row  d-inline-flex " *ngFor="let row of visibleData" (click)="redirectToInterviewPage(row.tId, row.PAId, row.id)">
            <td [width]="widths[0]" class="table-item">{{ row.priority }}</td>
            <td [width]="widths[1]" class="table-item">{{ row.status }}</td>
            <td [width]="widths[2]" class="table-item">{{ row.assigned_to }}</td>
            <td [width]="widths[3]" class="table-item">{{ row.tId }}</td>
            <td [width]="widths[4]" class="table-item">{{ row.PAId }}</td>
            <td [width]="widths[5]" class="table-item">{{ row.id }}</td>
            <td [width]="widths[6]" class="table-item">{{ row.fullName }}</td>
            <td [width]="widths[7]" class="table-item">{{ row.interviewer }}</td>
            <td [width]="widths[8]" class="table-item">{{ row.interviewLocation }}</td>
            <td [width]="widths[9]" class="table-item">{{ row.interviewDate }}</td>
            <td [width]="widths[10]" class="table-item">{{ row.gender }}</td>
            <td [width]="widths[11]" class="table-item">{{ row.dateOfBirth }}</td>
            <td [width]="widths[12]" class="table-item">{{ row.religion }}</td>
            <td [width]="widths[13]" class="table-item">{{ row.languages }}</td>
            <td [width]="widths[14]" class="table-item">{{ row.migratedFromAddress }}</td>
            <td [width]="widths[15]" class="table-item">{{ row.migratedToAddress }}</td>
            <td [width]="widths[16]" class="table-item">{{ row.postForm }}</td>
            <td [width]="widths[17]" class="table-item">{{ row.summary }}</td>
            <td [width]="widths[18]" class="table-item">{{ row.releaseForm }}</td>
            <td [width]="widths[19]" class="table-item">{{ row.photosAmount }}</td>
            <td [width]="widths[20]" class="table-item">{{ row.videoAudioReceived }}</td>
            <td [width]="widths[21]" class="table-item">{{ row.bRollReceived }}</td>
            <td [width]="widths[22]" class="table-item">{{ row.confirmEmailSent }}</td>
        </tr>
    </tbody>
</table>
