import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-interview-date',
  templateUrl: './interview-date.component.html',
  styleUrls: ['./interview-date.component.scss']
})
export class InterviewDateComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public interviewDateForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private parserFormatter: NgbDateParserFormatter,
    private authService: AuthService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {    
    this.backendService.getInterviewDate(this.interviewID).subscribe(interviewDate => {
      if (interviewDate) {
        this.interviewDateForm = this.fb.group({
          interviewDate: [this.parserFormatter.parse(interviewDate.interviewDate)],
          additionalInterviewDate1: [this.parserFormatter.parse(interviewDate.additionalInterviewDate1)],
          additionalInterviewDate2: [this.parserFormatter.parse(interviewDate.additionalInterviewDate2)],
          additionalInterviewDate3: [this.parserFormatter.parse(interviewDate.additionalInterviewDate3)],
          additionalInterviewDate4: [this.parserFormatter.parse(interviewDate.additionalInterviewDate4)],
          interviewArchivedDate: [this.parserFormatter.parse(interviewDate.interviewArchivedDate)],
          interviewReceivedDate: [this.parserFormatter.parse(interviewDate.interviewReceivedDate)],
        });

        this.setValidators();
      }
    });
  }

  ngOnInit() {
    this.interviewDateForm = this.fb.group({
      interviewDate: [null ],
      additionalInterviewDate1: [null],
      additionalInterviewDate2: [null],
      additionalInterviewDate3: [null],
      additionalInterviewDate4: [null],
      interviewArchivedDate: [null],
      interviewReceivedDate: [null],
    });

    this.setValidators();
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.interviewDateForm.controls['interviewDate'].setValidators(Validators.required);
      this.interviewDateForm.controls['interviewDate'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.interviewDateForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const interviewDate = this.parserFormatter.format(this.interviewDateForm.controls['interviewDate'].value);
    const additionalInterviewDate1 = this.parserFormatter.format(this.interviewDateForm.controls['additionalInterviewDate1'].value);
    const additionalInterviewDate2 = this.parserFormatter.format(this.interviewDateForm.controls['additionalInterviewDate2'].value);
    const additionalInterviewDate3 = this.parserFormatter.format(this.interviewDateForm.controls['additionalInterviewDate3'].value);
    const additionalInterviewDate4 = this.parserFormatter.format(this.interviewDateForm.controls['additionalInterviewDate4'].value);
    const interviewArchivedDate = this.parserFormatter.format(this.interviewDateForm.controls['interviewArchivedDate'].value);
    const interviewReceivedDate = this.parserFormatter.format(this.interviewDateForm.controls['interviewReceivedDate'].value);
    const interviewID = this.interviewID;
    let data = { interviewID, interviewDate, additionalInterviewDate1, additionalInterviewDate2, additionalInterviewDate3, additionalInterviewDate4, interviewArchivedDate, interviewReceivedDate };
    this.backendService.saveInterviewDate(data)
      .subscribe(() => this.toastr.success('Interview Date Information', 'Data saved successfully'))
    this.formSaved.emit();
  }

  get userType() {
    return this.authService.getUserType();
  }
}
