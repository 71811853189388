<app-form-container width="400px" height="520px" marginTop="3rem" marginBottom="10rem">
    <div class="w-100 text-center mt-5 mb-4">
        <label class="container-title">
            Signup
        </label>
    </div>
    <form *ngIf="!isRegistered" [formGroup]="registerForm">
        <div class="mx-4">
        <div class="form-group">
            <label for="email" class="layout-input-label">Email</label>
            <input id="email" class="form-control layout-input" formControlName="email">
        </div>
        <div class="form-group">
            <label for="fullName" class="layout-input-label">Full Name</label>
            <input id="fullName" class="form-control layout-input" formControlName="fullName">
        </div>
        <div class="form-group mb-1">
            <label for="password" class="layout-input-label">Password</label>
            <input type="password" id="password" class="form-control layout-input" formControlName="password">
        </div>
        </div>
        <div class="mt-4 text-center">
            <button class="login-button mx-auto" type="button" [disabled]="!registerForm.valid" style="cursor:pointer" (click)="onSubmit()">Register</button>
        </div>
    </form>
    <div *ngIf="isRegistered" style="text-align:center;padding:10px 0 0;font-weight:bold">
        Please check your email to verify your email address.
    </div>
    <div *ngIf="!isRegistered" class="mt-4 text-center">
        <label class="mx-auto font-weight-bold" routerLink="/login" style="cursor:pointer">Have an account? Log In</label>
    </div>
</app-form-container>