<div class="form-title mt-3 mb-3">
  Social Media Curation
</div>
<div class="form-data" [formGroup]="socialMediaCurationForm">
  <div class="form-group">
    <label for="archivist" class="required">Content Curator</label>
    <input type="text" class="form-input" id="contentCurator" formControlName="contentCurator">
  </div>
  <div class="form-group">
    <label for="contentFacebookURL">Curator Facebook URL</label>
    <input type="text" class="form-input" id="contentFacebookURL" formControlName="contentFacebookURL">
  </div>
  <div class="form-group">
    <label for="contentTwitterHandle">Curator Twitter Handle</label>
    <input type="text" class="form-input" id="contentTwitterHandle" formControlName="contentTwitterHandle">
  </div>
  <div class="form-group">
    <label for="contentInstagramHandle">Curator Instagram Handle</label>
    <input type="text" class="form-input" id="contentInstagramHandle" formControlName="contentInstagramHandle">
  </div>
  <div class="form-group">
    <label for="contentLinkedInProfile">Curator LinkedIn Profile</label>
    <input type="text" class="form-input" id="contentLinkedInProfile" formControlName="contentLinkedInProfile">
  </div>
  <div class="form-group">
    <label for="contentTikTokProfile">Curator TikTok Profile</label>
    <input type="text" class="form-input" id="contentTikTokProfile" formControlName="contentTikTokProfile">
  </div>
  <div class="form-group">
    <label for="liveFacebookPosted">Live Facebook Posted Version</label>
    <input type="text" class="form-input" id="liveFacebookPosted" formControlName="liveFacebookPosted">
  </div>
  <div class="form-group">
    <label for="linkToFacebookPosted">Link To Facebook Posted Version</label>
    <input type="text" class="form-input" id="linkToFacebookPosted" formControlName="linkToFacebookPosted">
  </div>
  <div class="form-group">
    <label for="liveInstagramPosted">Live Instagram Posted Version</label>
    <input type="text" class="form-input" id="liveInstagramPosted" formControlName="liveInstagramPosted">
  </div>
  <div class="form-group">
    <label for="linkToInstagramPosted">Link To Instagram Posted Version</label>
    <input type="text" class="form-input" id="linkToInstagramPosted" formControlName="linkToInstagramPosted">
  </div>
  <div class="form-group">
    <label for="liveLinkedInPosted">Live LinkedIn Posted Version</label>
    <input type="text" class="form-input" id="liveLinkedInPosted" formControlName="liveLinkedInPosted">
  </div>
  <div class="form-group">
    <label for="linkToLinkedInPosted">Link To LinkedIn Posted Version</label>
    <input type="text" class="form-input" id="linkToLinkedInPosted" formControlName="linkToLinkedInPosted">
  </div>
  <div class="form-group">
    <label for="liveTwitterPosted">Live Twitter Posted Version</label>
    <input type="text" class="form-input" id="liveTwitterPosted" formControlName="liveTwitterPosted">
  </div>
  <div class="form-group">
    <label for="linkToTwitterPosted">Link To Twitter Posted Version</label>
    <input type="text" class="form-input" id="linkToTwitterPosted" formControlName="linkToTwitterPosted">
  </div>
  <div class="form-group">
    <label for="dateOfPostedContent" class="required">Date of posted content</label>
    <div class="input-group w-50">
      <input class="form-datepicker" placeholder="MM/DD/YYYY" name="dp" ngbDatepicker #d="ngbDatepicker" formControlName="dateOfPostedContent" required>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
            <path fill-rule="evenodd" d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="postedOnSocialMedia">Posted on Social Media?</label>
    <input type="text" class="form-input" id="postedOnSocialMedia" formControlName="postedOnSocialMedia">
  </div>
  <div class="form-group">
    <label for="postedToFacebook">Posted to Facebook?</label>
    <input type="text" class="form-input" id="postedToFacebook" formControlName="postedToFacebook">
  </div>
  <div class="form-group">
    <label for="socialMediaNotes">Social Media Notes</label>
    <textarea id="socialMediaNotes" class="w-100 form-textarea" formControlName="socialMediaNotes" (paste)="onPaste($event, 'socialMediaNotes')"></textarea>
  </div>
  <div class="form-group">
    <label class="required">Email notification to family, next of kin and interviewer</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="emailNotification" value="Yes" id="yesEmailNotification" formControlName="emailNotification">
      <label class="form-check-label" for="yesEmailNotification">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="emailNotification" value="No" id="noEmailNotification" formControlName="emailNotification">
      <label class="form-check-label" for="noEmailNotification">
        No
      </label>
    </div>
  </div>
</div>
<div class="mt-5 text-center">
    <button class="save-button" (click)="saveForm()">Save</button>
</div>
