<app-submission-layout *ngIf="finishedLoading" [currentSubmissionStep]="currentSubmissionStep" [currentArchivistStep]="currentArchivistStep"
    [context]="context" (contextChanged)="changeContext($event)" (submissionStep)="changeSubmissionStep($event)"
    (archivistStep)="changeArchivistStep($event)">
    <div *ngIf="context == 'submission'">
        <app-interviewee-information [interviewID]="interviewID" *ngIf="currentSubmissionStep == 1" (formSaved)="nextStep()"></app-interviewee-information>
        <app-interviewer-information [interviewID]="interviewID" *ngIf="currentSubmissionStep == 2" (formSaved)="nextStep()"></app-interviewer-information>
        <app-narrator-information [interviewID]="interviewID" *ngIf="currentSubmissionStep == 3" (formSaved)="nextStep()"></app-narrator-information>
        <app-interview-date [interviewID]="interviewID" *ngIf="currentSubmissionStep == 4" (formSaved)="nextStep()"></app-interview-date>
        <app-interview-location [interviewID]="interviewID" *ngIf="currentSubmissionStep == 5" (formSaved)="nextStep()"></app-interview-location>
        <app-additional-information [interviewID]="interviewID" *ngIf="currentSubmissionStep == 6" (formSaved)="nextStep()"></app-additional-information>
        <app-archivist-abstract-curation [interviewID]="interviewID" *ngIf="currentSubmissionStep == 7" (formSaved)="nextStep()"></app-archivist-abstract-curation>
        <app-birthplace-location [interviewID]="interviewID" *ngIf="currentSubmissionStep == 8" (formSaved)="nextStep()"></app-birthplace-location>
        <app-residence-before-partition [interviewID]="interviewID" *ngIf="currentSubmissionStep == 9" (formSaved)="nextStep()"></app-residence-before-partition>
        <app-residence-after-partition [interviewID]="interviewID" *ngIf="currentSubmissionStep == 10" (formSaved)="nextStep()"></app-residence-after-partition>
        <app-migration-details [interviewID]="interviewID" *ngIf="currentSubmissionStep == 11" (formSaved)="nextStep()"></app-migration-details>
        <app-parental-information [interviewID]="interviewID" *ngIf="currentSubmissionStep == 12" (formSaved)="nextStep()"></app-parental-information>
        <app-family-information [interviewID]="interviewID" *ngIf="currentSubmissionStep == 13" (formSaved)="nextStep()"></app-family-information>
        <app-contact-information [interviewID]="interviewID" *ngIf="currentSubmissionStep == 14" (formSaved)="nextStep()"></app-contact-information>
        <app-interviewer-contact-information [interviewID]="interviewID" *ngIf="currentSubmissionStep == 15" (formSaved)="nextStep()"></app-interviewer-contact-information>
        <app-narrator-contact-information [interviewID]="interviewID" *ngIf="currentSubmissionStep == 16" (formSaved)="nextStep()"></app-narrator-contact-information>
        <app-interviewee-preferences [interviewID]="interviewID" *ngIf="currentSubmissionStep == 17" (formSaved)="nextStep()"></app-interviewee-preferences>
        <app-audio-video-files [interviewID]="interviewID" *ngIf="currentSubmissionStep == 18" (formSaved)="nextStep()"></app-audio-video-files>
        <app-photo-files [interviewID]="interviewID" *ngIf="currentSubmissionStep == 19" (formSaved)="nextStep()"></app-photo-files>
        <app-document-files [interviewID]="interviewID" *ngIf="currentSubmissionStep == 20" (formSaved)="nextStep()"></app-document-files>
        <app-other-files [interviewID]="interviewID" *ngIf="currentSubmissionStep == 21" (formSaved)="nextStep()"></app-other-files>
    </div>
    <div *ngIf="context == 'archivist'">
      <app-archivist-form [interviewID]="interviewID" *ngIf="currentArchivistStep == 1" (formSaved)="nextStep()"></app-archivist-form>
      <app-archivist-contact-information [interviewID]="interviewID" *ngIf="currentArchivistStep == 2" (formSaved)="nextStep()"></app-archivist-contact-information>
      <app-transcript-information [interviewID]="interviewID" *ngIf="currentArchivistStep == 3" (formSaved)="nextStep()"></app-transcript-information>
      <app-copy-editing-and-curation [interviewID]="interviewID" *ngIf="currentArchivistStep == 4" (formSaved)="nextStep()"></app-copy-editing-and-curation>
      <app-interview-review-content [interviewID]="interviewID" *ngIf="currentArchivistStep == 5" (formSaved)="nextStep()"></app-interview-review-content>
      <app-interview-review-courtesy [interviewID]="interviewID" *ngIf="currentArchivistStep == 6" (formSaved)="nextStep()"></app-interview-review-courtesy>
      <app-interview-review-filming [interviewID]="interviewID" *ngIf="currentArchivistStep == 7" (formSaved)="nextStep()"></app-interview-review-filming>
      <app-interview-review-response-reaction [interviewID]="interviewID" *ngIf="currentArchivistStep == 8" (formSaved)="nextStep()"></app-interview-review-response-reaction>
      <app-interview-review-other [interviewID]="interviewID" *ngIf="currentArchivistStep == 9" (formSaved)="nextStep()"></app-interview-review-other>
      <app-release-form-submission [interviewID]="interviewID" *ngIf="currentArchivistStep == 10" (formSaved)="nextStep()"></app-release-form-submission>
      <app-post-archiving-sponsor-information [interviewID]="interviewID" *ngIf="currentArchivistStep == 11" (formSaved)="nextStep()"></app-post-archiving-sponsor-information>
      <app-social-media-curation [interviewID]="interviewID" *ngIf="currentArchivistStep == 12" (formSaved)="nextStep()"></app-social-media-curation>
      <app-archivist-storymap [interviewID]="interviewID" *ngIf="currentArchivistStep == 13" (formSaved)="nextStep()"></app-archivist-storymap>
      <app-interviewee-communications [interviewID]="interviewID" *ngIf="currentArchivistStep == 14" (formSaved)="nextStep()"></app-interviewee-communications>
      <app-pseudonym [interviewID]="interviewID" *ngIf="currentArchivistStep == 15"></app-pseudonym>
    </div>
</app-submission-layout>
