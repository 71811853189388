<div class="form-title mt-3 mb-3">
    Interview Location
</div>
<div class="form-data" [formGroup]="interviewLocationForm">
  <div class="form-group">
    <label class="required">Was the interview conducted...</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="inPersonOrRemote" value="In-Person" id="yesInPerson" formControlName="inPersonOrRemote">
      <label class="form-check-label" for="yesInPerson">
        In-Person
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="inPersonOrRemote" value="Remote" id="noInPerson" formControlName="inPersonOrRemote">
      <label class="form-check-label" for="noInPerson">
        Remote
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="houseNumber">House number</label>
    <input type="text" class="form-input" id="houseNumber" formControlName="houseNumber">
  </div>
  <div class="form-group">
    <label for="street">Street</label>
    <input type="text" class="form-input" id="street" formControlName="street">
  </div>
  <div class="form-group">
    <label for="city" class="required">City/Village</label>
    <input type="text" class="form-input" id="city" formControlName="city">
  </div>
  <div class="form-group">
    <label for="district">District/tehsil/Jilla</label>
    <input type="text" class="form-input" id="district" formControlName="district">
  </div>
  <div class="form-group">
    <label for="state" class="required">State/Province/Division</label>
    <input type="text" class="form-input" id="state" formControlName="state">
  </div>
  <div class="form-group">
    <label for="country" class="required">Country</label>
    <input type="text" class="form-input" id="country" formControlName="country">
  </div>
  <div class="form-group">
    <label for="postalCode">Zip/Postal Code</label>
    <input type="text" class="form-input" id="postalCode" formControlName="postalCode">
  </div>
  <div class="form-group">
    <label for="town">For City/Village/Town: precise details for interview location address</label>
    <input type="text" class="form-input" id="town" formControlName="town">
  </div>
  <p style="margin-top:33px">
    For latitude and longitude coordinates, write the information according to this format: <b>##.####</b>. Do not input North (N) or South (S), East (E), or West (W) letters, or degree sign. 
    <br><br>
    <span style="text-decoration:underline">Example:</span>
    <br>
    Berkeley, California, USA: <b>37.8717</b> (latitude) and <b>-122.2728</b> (longitude)
    <br>
    Lahore, Punjab, Pakistan: <b>31.5204</b> (latitude) and <b>74.3587</b> (longitude)
    <br>
    Perth, Western Australia, Australia: <b>-31.9505</b> (latitude) and <b>115.8605</b> (longitude)
  </p>
  <div class="form-group">
    <label for="interviewLocation" class="required">Interview Location (City, State/Province, Country)</label>
    <input type="text" class="form-input" id="interviewLocation" formControlName="interviewLocation">
  </div>
  <div class="form-group">
    <label for="latitudeInterviewLocation" class="required">Interview Location Latitude</label>
    <input type="text" class="form-input" id="latitudeInterviewLocation" formControlName="latitudeInterviewLocation">
  </div>
  <div class="form-group">
    <label for="longitudeInterviewLocation" class="required">Interview Location Longitude</label>
    <input type="text" class="form-input" id="longitudeInterviewLocation" formControlName="longitudeInterviewLocation">
  </div>
</div>
<div class="mt-5 text-center">
    <button class="save-button" (click)="saveForm()">Save</button>
</div>
