import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-interviewee-information',
  templateUrl: './interviewee-information.component.html',
  styleUrls: ['./interviewee-information.component.scss']
})
export class IntervieweeInformationComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public intervieweeForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private backendService: BackendService,
    private toastr: ToastrService,
    private parserFormatter: NgbDateParserFormatter,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.intervieweeForm = this.fb.group({
      title: [null],
      firstName: [null],
      middleName: [null],
      lastName: [null],
      suffix: [null],
      nameAtBirth: [null],
      alternateSpellings: [null],
      fullName: [null],
      dateOfBirth: [null],
      inexactDateOfBirth: [null],
      intervieweeAge: [null],
      gender: [null],
      religion: [null],
      religiousConversion: [null],
      conversionNotes: [null],
      intervieweeNativeLanguage: [null],
      intervieweeRegionalLanguage: [null],
      intervieweeRace: [null],
      deceased: [null],
      referenceNumber: [null]
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getInterviewee(this.interviewID).subscribe(interviewee => {
        if (interviewee) {
          this.intervieweeForm = this.fb.group({
            title: [ interviewee.title ],
            firstName: [ interviewee.firstName ],
            middleName: [ interviewee.middleName ],
            lastName: [ interviewee.lastName ],
            suffix: [ interviewee.suffix ],
            nameAtBirth: [ interviewee.nameAtBirth ],
            alternateSpellings: [interviewee.alternateSpellings],
            fullName: [interviewee.fullName],
            dateOfBirth: [interviewee.dateOfBirth],
            inexactDateOfBirth: [interviewee.inexactDateOfBirth],
            intervieweeAge: [interviewee.intervieweeAge],
            gender: [interviewee.gender],
            religion: [interviewee.religion],
            religiousConversion: [interviewee.religiousConversion],
            conversionNotes: [interviewee.conversionNotes],
            intervieweeNativeLanguage: [interviewee.intervieweeNativeLanguage],
            intervieweeRegionalLanguage: [interviewee.intervieweeRegionalLanguage],
            intervieweeRace: [interviewee.intervieweeRace],
            deceased: [null],
            referenceNumber: [null]
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.intervieweeForm.controls['firstName'].setValidators(Validators.required);
      this.intervieweeForm.controls['firstName'].updateValueAndValidity();
      this.intervieweeForm.controls['middleName'].setValidators(Validators.required);
      this.intervieweeForm.controls['middleName'].updateValueAndValidity();
      this.intervieweeForm.controls['lastName'].setValidators(Validators.required);
      this.intervieweeForm.controls['lastName'].updateValueAndValidity();
      this.intervieweeForm.controls['fullName'].setValidators(Validators.required);
      this.intervieweeForm.controls['fullName'].updateValueAndValidity();
      this.intervieweeForm.controls['dateOfBirth'].setValidators(Validators.required);
      this.intervieweeForm.controls['dateOfBirth'].updateValueAndValidity();
      this.intervieweeForm.controls['intervieweeAge'].setValidators(Validators.required);
      this.intervieweeForm.controls['intervieweeAge'].updateValueAndValidity();
      this.intervieweeForm.controls['gender'].setValidators(Validators.required);
      this.intervieweeForm.controls['gender'].updateValueAndValidity();
      this.intervieweeForm.controls['religion'].setValidators(Validators.required);
      this.intervieweeForm.controls['religion'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.intervieweeForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const title = this.intervieweeForm.controls['title'].value;
    const firstName = this.intervieweeForm.controls['firstName'].value;
    const middleName = this.intervieweeForm.controls['middleName'].value;
    const lastName = this.intervieweeForm.controls['lastName'].value;
    const suffix = this.intervieweeForm.controls['suffix'].value;
    const nameAtBirth = this.intervieweeForm.controls['nameAtBirth'].value;
    const alternateSpellings = this.intervieweeForm.controls['alternateSpellings'].value;
    const fullName = this.intervieweeForm.controls['fullName'].value;
    const dateOfBirth = this.intervieweeForm.controls['dateOfBirth'].value;
    const inexactDateOfBirth = this.intervieweeForm.controls['inexactDateOfBirth'].value;
    const intervieweeAge = this.intervieweeForm.controls['intervieweeAge'].value;
    const gender = this.intervieweeForm.controls['gender'].value;
    const religion = this.intervieweeForm.controls['religion'].value;
    const religiousConversion = this.intervieweeForm.controls['religiousConversion'].value;
    const conversionNotes = this.intervieweeForm.controls['conversionNotes'].value;
    const intervieweeNativeLanguage = this.intervieweeForm.controls['intervieweeNativeLanguage'].value;
    const intervieweeRegionalLanguage = this.intervieweeForm.controls['intervieweeRegionalLanguage'].value;
    const intervieweeRace = this.intervieweeForm.controls['intervieweeRace'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      title,
      firstName,
      middleName,
      lastName,
      suffix,
      nameAtBirth,
      alternateSpellings,
      fullName,
      dateOfBirth,
      inexactDateOfBirth,
      intervieweeAge,
      gender,
      religion,
      religiousConversion,
      conversionNotes,
      intervieweeNativeLanguage,
      intervieweeRegionalLanguage,
      intervieweeRace
    };
    this.backendService.saveInterviewee(data)
      .subscribe((result) =>
      {
        if (this.interviewID == null) {
          this.interviewID = result.interviewID
          this.formSaved.emit();
          this.toastr.success('Interviewee Information', 'Data saved successfully');
          this.router.navigate(['/submission-form/t/' + result.interviewID]);
        }
        else {
          this.formSaved.emit();
          this.toastr.success('Interviewee Information', 'Data saved successfully');
        }
      })
  }

  get userType() {
    return this.authService.getUserType();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
