<div class="form-title mt-3 mb-3">
  Pseudonym
</div>
<div class="form-data" [formGroup]="pseudonymForm">
  <div class="form-group">
    <label for="pseudonym">Randomized Unique Reference Number (Pseudonym)</label>
    <input type="text" class="form-input" id="pseudonym" formControlName="pseudonym">
  </div>
</div>
<div class="mt-5 text-center">
    <button class="save-button" (click)="saveForm()">Save</button>
</div>
