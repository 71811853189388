import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public contactInformationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.contactInformationForm = this.fb.group({
      houseNumberCurrentResidence: [null ],
      streetCurrentResidence: [null ],
      cityCurrentResidence: [null ],
      stateCurrentResidence: [null ],
      districtCurrentResidence: [null ],
      postalCodeCurrentResidence: [null ],
      countryCurrentResidence: [null ],
      villageDetails: [null],
      latitudeCurrentResidence: [null ],
      longitudeCurrentResidence: [null ],
      phoneNumberInterviewee: [null ],
      emailInterviewee: [null ],
      currentResidence: [null],
      facebook: [null],
      twitter: [null],
      instagram: [null],
      linkedin: [null],
      tiktok: [null],
      contactInformationAdditionalNotes: [null]
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getContactInformation(this.interviewID).subscribe(information => {
        if (information) {
          this.contactInformationForm = this.fb.group({
            houseNumberCurrentResidence: [ information.houseNumberCurrentResidence ],
            streetCurrentResidence: [ information.streetCurrentResidence ],
            cityCurrentResidence: [ information.cityCurrentResidence ],
            stateCurrentResidence: [ information.stateCurrentResidence ],
            districtCurrentResidence: [ information.districtCurrentResidence ],
            postalCodeCurrentResidence: [ information.postalCodeCurrentResidence ],
            countryCurrentResidence: [ information.countryCurrentResidence ],
            villageDetails: [ information.villageDetails],
            latitudeCurrentResidence: [ information.latitudeCurrentResidence ],
            longitudeCurrentResidence: [ information.longitudeCurrentResidence ],
            phoneNumberInterviewee: [ information.phoneNumberInterviewee ],
            emailInterviewee: [ information.emailInterviewee ],
            currentResidence: [ information.currentResidence ],
            facebook: [ information.facebook ],
            twitter: [ information.twitter ],
            instagram: [ information.instagram ],
            linkedin : [ information.linkedin ],
            tiktok: [ information.tiktok ],
            contactInformationAdditionalNotes: [ information.contactInformationAdditionalNotes ]
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.contactInformationForm.controls['currentResidence'].setValidators(Validators.required);
      this.contactInformationForm.controls['currentResidence'].updateValueAndValidity();
      this.contactInformationForm.controls['latitude'].setValidators(Validators.required);
      this.contactInformationForm.controls['latitude'].updateValueAndValidity();
      this.contactInformationForm.controls['longitude'].setValidators(Validators.required);
      this.contactInformationForm.controls['longitude'].updateValueAndValidity();
      this.contactInformationForm.controls['phoneNumber'].setValidators(Validators.required);
      this.contactInformationForm.controls['phoneNumber'].updateValueAndValidity();
      this.contactInformationForm.controls['houseNumber'].setValidators(Validators.required);
      this.contactInformationForm.controls['houseNumber'].updateValueAndValidity();
      this.contactInformationForm.controls['street'].setValidators(Validators.required);
      this.contactInformationForm.controls['street'].updateValueAndValidity();
      this.contactInformationForm.controls['city'].setValidators(Validators.required);
      this.contactInformationForm.controls['city'].updateValueAndValidity();
      this.contactInformationForm.controls['district'].setValidators(Validators.required);
      this.contactInformationForm.controls['district'].updateValueAndValidity();
      this.contactInformationForm.controls['state'].setValidators(Validators.required);
      this.contactInformationForm.controls['state'].updateValueAndValidity();
      this.contactInformationForm.controls['postalCode'].setValidators(Validators.required);
      this.contactInformationForm.controls['postalCode'].updateValueAndValidity();
      this.contactInformationForm.controls['country'].setValidators(Validators.required);
      this.contactInformationForm.controls['country'].updateValueAndValidity();
      this.contactInformationForm.controls['phone'].setValidators(Validators.required);
      this.contactInformationForm.controls['phone'].updateValueAndValidity();
      this.contactInformationForm.controls['email'].setValidators(Validators.required);
      this.contactInformationForm.controls['email'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.contactInformationForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const houseNumberCurrentResidence = this.contactInformationForm.controls['houseNumberCurrentResidence'].value;
    const streetCurrentResidence = this.contactInformationForm.controls['streetCurrentResidence'].value;
    const cityCurrentResidence = this.contactInformationForm.controls['cityCurrentResidence'].value;
    const stateCurrentResidence = this.contactInformationForm.controls['stateCurrentResidence'].value;
    const districtCurrentResidence = this.contactInformationForm.controls['districtCurrentResidence'].value;
    const postalCodeCurrentResidence = this.contactInformationForm.controls['postalCodeCurrentResidence'].value;
    const countryCurrentResidence = this.contactInformationForm.controls['countryCurrentResidence'].value;
    const villageDetails = this.contactInformationForm.controls['villageDetails'].value;
    const latitudeCurrentResidence = this.contactInformationForm.controls['latitudeCurrentResidence'].value;
    const longitudeCurrentResidence = this.contactInformationForm.controls['longitudeCurrentResidence'].value;
    const phoneNumberInterviewee = this.contactInformationForm.controls['phoneNumberInterviewee'].value;
    const emailInterviewee = this.contactInformationForm.controls['emailInterviewee'].value;
    const currentResidence = [ this.contactInformationForm.controls['currentResidence'].value ];
    const facebook = this.contactInformationForm.controls['facebook'].value;
    const twitter = this.contactInformationForm.controls['twitter'].value;
    const instagram = this.contactInformationForm.controls['instagram'].value;
    const linkedin = this.contactInformationForm.controls['linkedin'].value;
    const tiktok = this.contactInformationForm.controls['tiktok'].value;
    const contactInformationAdditionalNotes = this.contactInformationForm.controls['contactInformationAdditionalNotes'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      houseNumberCurrentResidence,
      streetCurrentResidence,
      cityCurrentResidence,
      stateCurrentResidence,
      districtCurrentResidence,
      postalCodeCurrentResidence,
      countryCurrentResidence,
      villageDetails,
      latitudeCurrentResidence,
      longitudeCurrentResidence,
      phoneNumberInterviewee,
      emailInterviewee,
      currentResidence,
      facebook,
      twitter,
      instagram,
      linkedin,
      tiktok,
      contactInformationAdditionalNotes
    };
    this.backendService.saveContactInformation(data)
      .subscribe(response => {
        if (response && response.error) {
          this.toastr.error('Interviewee Contact Information', response.error);
        } else {
          this.toastr.success('Interviewee Contact Information', 'Data saved successfully');
          this.formSaved.emit();
        }
    });
  }

  get userType() {
    return this.authService.getUserType();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
