import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { RecordsSearchComponent } from './records-search/records-search.component';
import { RegisterComponent } from './register/register.component';
import { SubmissionFormComponent } from './submission-form/submission-form.component';

const routes: Routes = [
    {
        path:'',
        component: RecordsSearchComponent,
        canActivate:[AuthGuardService]
    },
    {
      path:'submission-form',
      canActivate:[ AuthGuardService ],
      children: [
        {
            path: '',
            component: SubmissionFormComponent
        },
        {
            path: ':id',
            component: SubmissionFormComponent
        },
        {
            path: 't/:tId',
            component: SubmissionFormComponent
        },
        {
            path: 'pa/:paId',
            component: SubmissionFormComponent
        }
      ]
    },
    {
      path:'login',
      component: LoginComponent
    },
    {
      path:'signup',
      component: RegisterComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }