<div class="form-title mt-3 mb-3">
    Interviewee Residence After Partition
</div>
<div class="form-data" [formGroup]="residenceAfterPartitionForm">
    <div class="form-group">
        <label for="migrationQuestion" class="required">Did the interviewee migrate during Partition time?</label>
        <input type="text" class="form-input" id="migrationQuestion" formControlName="migrationQuestion">
    </div>
    <div class="form-group">
        <label for="houseNumberAfterPartition">House Number of residence after Partition</label>
        <input type="text" class="form-input" id="houseNumberAfterPartition" formControlName="houseNumberAfterPartition">
    </div>
    <div class="form-group">
        <label for="streetAfterPartition">Street of residence after Partition</label>
        <input type="text" class="form-input" id="streetAfterPartition"  formControlName="streetAfterPartition">
    </div>
    <div class="form-group">
        <label for="cityAfterPartition" class="required">City/Village of residence after Partition</label>
        <input type="text" class="form-input" id="cityAfterPartition" formControlName="cityAfterPartition">
    </div>
    <div class="form-group">
        <label for="districtAfterPartition">District/Tehsil/Jilla of residence after Partition</label>
        <input type="text" class="form-input" id="districtAfterPartition" formControlName="districtAfterPartition">
    </div>
    <div class="form-group">
        <label for="stateAfterPartition" class="required">State/Province/Division of residence after Partition</label>
        <input type="text" class="form-input" id="stateAfterPartition"  formControlName="stateAfterPartition">
    </div>
    <div class="form-group">
        <label for="countryAfterPartition" class="required">Country of residence after Partition</label>
        <input type="text" class="form-input" id="countryAfterPartition"  formControlName="countryAfterPartition">
    </div>
    <div class="form-group">
        <label for="postalCodeAfterPartition">Zip/Postal Code of residence after Partition</label>
        <input type="text" class="form-input" id="postalCodeAfterPartition" formControlName="postalCodeAfterPartition">
    </div>
    <div class="form-group">
        <label for="villageAfterPartition">For Village/Town: Precise details for residence after Partition</label>
        <input type="text" class="form-input" id="villageAfterPartition"  formControlName="villageAfterPartition">
    </div>
    <p style="margin-top:33px">
        For latitude and longitude coordinates, write the information according to this format: <b>##.####</b>. Do not input North (N) or South (S), East (E), or West (W) letters, or degree sign. 
        <br><br>
        <span style="text-decoration:underline">Example:</span>
        <br>
        Berkeley, California, USA: <b>37.8717</b> (latitude) and <b>-122.2728</b> (longitude)
        <br>
        Lahore, Punjab, Pakistan: <b>31.5204</b> (latitude) and <b>74.3587</b> (longitude)
        <br>
        Perth, Western Australia, Australia: <b>-31.9505</b> (latitude) and <b>115.8605</b> (longitude)
    </p>
    <div class="form-group">
        <label for="migratedToAddress" class="required">Migrated To (Village/City, State, Country)</label>
        <input type="text" class="form-input" id="migratedToAddress" formControlName="migratedToAddress">
      </div>
    <div class="form-group">
        <label for="migratedToLatitude" class="required">Latitude of residence after Partition</label>
        <input type="text" class="form-input" id="migratedToLatitude"  formControlName="migratedToLatitude">
    </div>
    <div class="form-group">
        <label for="migratedToLongitude" class="required">Longitude of residence after Partition</label>
        <input type="text" class="form-input" id="migratedToLongitude"  formControlName="migratedToLongitude">
    </div>
</div>
<div class="mt-5 text-center">
    <button class="save-button" (click)="saveForm()">Save</button>
</div>
