import { Component, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-records-search',
  templateUrl: './records-search.component.html',
  styleUrls: ['./records-search.component.scss']
})
export class RecordsSearchComponent implements OnInit {
  public tableData = [];

  public tableColumns = [
    { name: 'Priority', sortable: true, sortColumn:'priority' },
    { name: 'Status', sortable: true, sortColumn:'status' },
    { name: 'Assigned To', sortable: true, sortColumn: 'assigned_to'},
    { name: 'T ID', sortable: true, sortColumn: 'tId'},
    { name: 'PA ID', sortable: true, sortColumn: 'paId'},
    { name: 'ID', sortable: true, sortColumn: 'id' },
    { name: 'Interviewee Name', sortable: true, sortColumn: 'fullName'},
    { name: 'Interviewer', sortable: true, sortColumn: 'interviewer'},
    { name: 'Interview location (City, State/Province, Country)', sortable: true, sortColumn: 'interviewLocation'},
    { name: 'Interview Date', sortable: true, sortColumn: 'InterviewDate'},
    { name: 'Gender of interviewee', sortable: true, sortColumn: 'gender'},
    { name: 'Interviewee Date of Birth', sortable: true, sortColumn: 'dateOfBirth'},
    { name: 'Interviewee Religion', sortable: true, sortColumn: 'religion'},
    { name: 'Interview Languages', sortable: true, sortColumn: 'languages'},
    { name: '"Migrated From (Village/City, State, Country)"', sortable: true, sortColumn: 'migratedFromAddress'},
    { name: '"Migrated To (Village/City, State, Country)"', sortable: true, sortColumn: 'migratedToAddress'},
    { name: 'POST Form', sortable: true, sortColumn: 'postForm'},
    { name: 'Summary', sortable: true, sortColumn: 'summary'},
    { name: 'RELEASE Form', sortable: true, sortColumn: 'releaseForm'},
    { name: '# Photos of interview', sortable: true, sortColumn: 'olderPhotosAmount'},
    { name: 'Video/Audio Received', sortable: true, sortColumn: 'videoAudioReceived'},
    { name: 'B-Roll Received', sortable: true, sortColumn: 'bRollReceived'},
    { name: 'Doc & Video confirm email sent', sortable: true, sortColumn: 'confirmEmailSent'}
  ]

  public currentPage = 0;
  public perPage = 100;
  public totalCount = 0;
  public totalPages = 0;
  public searchText;
  public facets;
  public pageIndices = [];
  public isLoading = false;
  public isDownloadLoading = false;
  public isStoryMapDownloadLoading = false;

  public selectedStatuses: string[] = [];
  get allStatusSelected(): boolean {
    return this.selectedStatuses && this.facets.statuses && this.selectedStatuses.length > 0 && this.selectedStatuses.length === this.facets.statuses.length;
  }

  public selectedPriorities: string[] = [];
  get allPrioritiesSelected(): boolean {
    return this.selectedPriorities && this.facets.priorities && this.selectedPriorities.length > 0 && this.selectedPriorities.length === this.facets.priorities.length;
  }

  public selectedAssignments: string[] = [];
  get allAssignmentsSelected(): boolean {
    return this.selectedAssignments && this.facets.assignments && this.selectedAssignments.length > 0 && this.selectedAssignments.length === this.facets.assignments.length;
  }

  constructor(
    private backendService: BackendService,
    private authService: AuthService,
    private searchService: SearchService
  ) { }

  ngOnInit() {
    this.loadFromSearchService();
    this.refreshData();
  }

  changePage(page: number) {
    this.currentPage = page;
  }

  refreshData() {
    this.isLoading = true;
    this.tableData = [];
    this.facets = {};

    this.backendService.getFacets()
    .pipe(
      switchMap(facets => {
        this.facets = facets;

        const statuses = !this.facets || this.selectedStatuses.length === this.facets.statuses.length ? [] : this.selectedStatuses;
        const assignments = !this.facets || this.selectedAssignments.length === this.facets.assignments.length ? [] : this.selectedAssignments;
        const priorities = !this.facets || this.selectedPriorities.length === this.facets.priorities.length ? [] : this.selectedPriorities;

        return this.backendService.getInterviews(this.searchText, this.currentPage, this.perPage, {assignments, statuses, priorities }, this.authService.getUserType());
      })
    ).subscribe(results => {
      const { data = [], total = 0 } = results;
      data.forEach(row => {
        this.tableData.push({
          interviewID: row.interviewID,
          priority: row.priority,
          status: row.status,
          assigned_to: row.assignedTo,
          tId: row.status === 'Submission In Progress' && !row.permanentInterviewID ? row.interviewID : '',
          PAId: (row.status === 'Archiving In Progress' || row.status === 'Submitted/Received') && !row.permanentInterviewID ? row.interviewID : '',
          id: row.permanentInterviewID,
          fullName: row.fullName, 
          interviewer: row.interviewer, 
          interviewLocation: row.interviewLocation,
          interviewDate: row.interviewDate,
          gender: row.gender,
          dateOfBirth: row.dateOfBirth,
          religion: row.religion,
          languages: row.languages,
          migratedFromAddress: row.migratedFromAddress,
          migratedToAddress: row.migratedToAddress,
          postForm: row.postForm,
          summary: row.summary,
          releaseForm: row.releaseForm,
          photosAmount: row.photosAmount,
          videoAudioReceived: row.videoAudioReceived,
          bRollReceived: row.bRollReceived,
          confirmEmailSent: row.confirmEmailSent
        });
      });

      // Clone tableData to trigger ngOnChanges again in app-records-table
      this.tableData = this.tableData.slice();

      this.totalCount = total;
      this.totalPages = Math.ceil(this.totalCount / this.perPage);
      this.pageIndices = [...Array(this.totalPages).keys()];
      this.isLoading = false;
    });
  }

  loadFromSearchService() {
    const { searchText, selectedStatuses, selectedAssignments } = this.searchService.loadFromSearchService();

    this.searchText = searchText;
    this.selectedStatuses = selectedStatuses;
    this.selectedAssignments = selectedAssignments;
  }

  search(): void {
    this.currentPage = 0;
    this.totalCount = 0;
    this.searchService.clearAll();
    this.selectedStatuses = [];
    this.selectedAssignments = [];
    this.searchService.updateSearchText(this.searchText);
    this.refreshData(); 
  }

  selectAllStatus() {
    if (this.allStatusSelected) {
      this.selectedStatuses = [];
    } else {
      this.selectedStatuses = [...this.facets.statuses];
    }

    this.refreshData();
    this.searchService.updateStatuses(this.selectedStatuses);
  }

  selectStatus(event, status) {
    const index = this.selectedStatuses.findIndex((s) => s === status);
    if(index > -1) {
      this.selectedStatuses.splice(index, 1);
    } else {
      this.selectedStatuses = [...this.selectedStatuses, status];
    }

    this.refreshData();
    this.searchService.updateStatuses(this.selectedStatuses);
  }

   selectAllPriorities() {
    if(this.allPrioritiesSelected) {
      this.selectedPriorities = [];
    } else {
      this.selectedPriorities = [...this.facets.priorities];
    }
  }

  selectPriority(event, priority) {
    const index = this.selectedPriorities.findIndex((p) => p === priority);
    if(index > -1) {
      this.selectedPriorities.splice(index, 1);
    } else {
      this.selectedPriorities = [...this.selectedPriorities, priority];
    }
  } 

  selectAllAssignments() {
    if(this.allAssignmentsSelected) {
      this.selectedAssignments = [];
    } else {
      this.selectedAssignments = [...this.facets.assignments];
    }

    this.refreshData();
    this.searchService.updateAssignments(this.selectedAssignments);
  }

  selectAssignment(event, assignment) {
    const index = this.selectedAssignments.findIndex((a) => a === assignment);
    if(index > -1) {
      this.selectedAssignments.splice(index, 1);
    } else {
      this.selectedAssignments = [...this.selectedAssignments, assignment];
    }

    this.refreshData();
    this.searchService.updateAssignments(this.selectedAssignments);
  }

  downloadFile() {
    this.isDownloadLoading = true;
    this.backendService.exportAllData().subscribe(
      response => {
        this.isDownloadLoading = false;
      }
    );
  }

  downloadStoryMap() {
    this.isStoryMapDownloadLoading = true;
    this.backendService.exportStoryMap().subscribe(
      response => {
        this.isStoryMapDownloadLoading = false;
      }
    );
  }

  get userType() {
    return this.authService.getUserType();
  }
}
