<div class="form-title mt-3 mb-3">
    Additional Interview Information
</div>
<div class="form-data" [formGroup]="additionalInformationForm">
    <div class="form-group">
        <label for="languages" class="required">Interview Languages</label>
        <input type="text" class="form-input" id="languages"  formControlName="languages">
    </div>
    <div class="form-group">
        <label for="languagesNotes">Interview Languages: Notes</label>
        <textarea id="languagesNotes" class="w-100 form-textarea" formControlName="languagesNotes" (paste)="onPaste($event, 'languagesNotes')"></textarea>
    </div>
</div>
<div class="mt-5 text-center">
    <button class="save-button" (click)="saveForm()">Save</button>
</div>

