
<div class="form-title mt-3 mb-5">
    Interview Audio/Video Files
    <div class="mt-3" style="text-align:left;font-size:14px;margin:0 50px">
      <span style="text-decoration:underline">File Naming Convention:</span>
      <br><br>
      <b>Video files:</b> Firstname_Middlename_Lastname_mm-dd-yyyy_VIDEO_#of#
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_VIDEO_1of2
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_VIDEO_2of2
      <br><br>
      <b>Audio files:</b> Firstname_Middlename_Lastname_mm-dd-yyyy_AUDIO_#of#
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_AUDIO_1of2
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_AUDIO_2of2
      <br><br>
      <b>B-Roll:</b> Firstname_Middlename_Lastname_mm-dd-yyyy_BROLL_#of#
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_BROLL_1of1
    </div>

  <div id="div_video"> </div>
</div>

<app-file-uploader [fileType]="fileType" [interviewID]="interviewID"></app-file-uploader>
