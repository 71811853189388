import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-interviewee-preferences',
  templateUrl: './interviewee-preferences.component.html',
  styleUrls: ['./interviewee-preferences.component.scss']
})
export class IntervieweePreferencesComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public intervieweePreferencesForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.intervieweePreferencesForm = this.fb.group({
      projectWebsite: [ null ],
      onlineVideoChannel: [ null ],
      socialMedia: [ null ],
      radioBroadcast: [ null ],
      tvBroadcast: [ null ],
      printAgreement: [ null ],
      delayOption: [ null ],
      delayDate: [ null ],
      speakingEvents: [ null ],
      moreDetails: [ null ],
      permissions : [ null ],
      notesPermissions: [ null ]
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.backendService.getIntervieweePermissions(this.interviewID).subscribe(intervieweePermissions => {
      if (intervieweePermissions) {
        this.intervieweePreferencesForm = this.fb.group({
          projectWebsite: [ this.capitalizeFirstLetter(intervieweePermissions.projectWebsite) ],
          onlineVideoChannel: [ this.capitalizeFirstLetter(intervieweePermissions.onlineVideoChannel) ],
          socialMedia: [ this.capitalizeFirstLetter(intervieweePermissions.socialMedia) ],
          radioBroadcast: [ this.capitalizeFirstLetter(intervieweePermissions.radioBroadcast) ],
          tvBroadcast: [ this.capitalizeFirstLetter(intervieweePermissions.tvBroadcast) ],
          printAgreement: [ this.capitalizeFirstLetter(intervieweePermissions.printAgreement) ],
          delayOption: [ this.capitalizeFirstLetter(intervieweePermissions.delayOption) ],
          delayDate: [ intervieweePermissions.delayDate ],
          speakingEvents: [ this.capitalizeFirstLetter(intervieweePermissions.speakingEvents) ],
          moreDetails: [ this.capitalizeFirstLetter(intervieweePermissions.moreDetails) ],
          permissions: [ intervieweePermissions.permissions ],
          notesPermissions: [ intervieweePermissions.notesPermissions ],
        });

        this.setValidators();
      }
    });
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.intervieweePreferencesForm.controls['projectWebsite'].setValidators(Validators.required);
      this.intervieweePreferencesForm.controls['projectWebsite'].updateValueAndValidity();
      this.intervieweePreferencesForm.controls['onlineVideoChannel'].setValidators(Validators.required);
      this.intervieweePreferencesForm.controls['onlineVideoChannel'].updateValueAndValidity();
      this.intervieweePreferencesForm.controls['socialMedia'].setValidators(Validators.required);
      this.intervieweePreferencesForm.controls['socialMedia'].updateValueAndValidity();
      this.intervieweePreferencesForm.controls['radioBroadcast'].setValidators(Validators.required);
      this.intervieweePreferencesForm.controls['radioBroadcast'].updateValueAndValidity();
      this.intervieweePreferencesForm.controls['tvBroadcast'].setValidators(Validators.required);
      this.intervieweePreferencesForm.controls['tvBroadcast'].updateValueAndValidity();
      this.intervieweePreferencesForm.controls['printAgreement'].setValidators(Validators.required);
      this.intervieweePreferencesForm.controls['printAgreement'].updateValueAndValidity();
      this.intervieweePreferencesForm.controls['delayOption'].setValidators(Validators.required);
      this.intervieweePreferencesForm.controls['delayOption'].updateValueAndValidity();
      this.intervieweePreferencesForm.controls['speakingEvents'].setValidators(Validators.required);
      this.intervieweePreferencesForm.controls['speakingEvents'].updateValueAndValidity();
      this.intervieweePreferencesForm.controls['moreDetails'].setValidators(Validators.required);
      this.intervieweePreferencesForm.controls['moreDetails'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.intervieweePreferencesForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const projectWebsite = this.intervieweePreferencesForm.controls['projectWebsite'].value;
    const onlineVideoChannel = this.intervieweePreferencesForm.controls['onlineVideoChannel'].value;
    const socialMedia = this.intervieweePreferencesForm.controls['socialMedia'].value;
    const radioBroadcast = this.intervieweePreferencesForm.controls['radioBroadcast'].value;
    const tvBroadcast = this.intervieweePreferencesForm.controls['tvBroadcast'].value;
    const printAgreement = this.intervieweePreferencesForm.controls['printAgreement'].value;
    const delayOption = this.intervieweePreferencesForm.controls['delayOption'].value;
    const delayDate = this.intervieweePreferencesForm.controls['delayDate'].value;
    const speakingEvents = this.intervieweePreferencesForm.controls['speakingEvents'].value;
    const moreDetails = this.intervieweePreferencesForm.controls['moreDetails'].value;
    const permissions = this.intervieweePreferencesForm.controls['permissions'].value;
    const notesPermissions = this.intervieweePreferencesForm.controls['notesPermissions'].value;
    const interviewID = this.interviewID;
    let data = { interviewID, projectWebsite, onlineVideoChannel, socialMedia, radioBroadcast, tvBroadcast, printAgreement, delayOption, delayDate, speakingEvents, moreDetails, permissions, notesPermissions };
    this.backendService.saveIntervieweePermissions(data)
      .subscribe(() => this.toastr.success('Interviewee Preferences', 'Data saved successfully'))
    this.formSaved.emit();
  }

  capitalizeFirstLetter(stringInput) {
    if (stringInput && stringInput.length > 0) {
      return stringInput.charAt(0).toUpperCase() + stringInput.slice(1);
    }

    return stringInput;
  }

  get userType(): string {
    return this.authService.getUserType();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
