import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public additionalInformationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.additionalInformationForm = this.fb.group({
      languages: [null],
      languagesNotes: [null]
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getAdditionalInformation(this.interviewID).subscribe(information => {
        if (information) {
          this.additionalInformationForm = this.fb.group({
            languages: [ information.languages ],
            languagesNotes: [ information.languagesNotes ]
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.additionalInformationForm.controls['languages'].setValidators(Validators.required);
      this.additionalInformationForm.controls['languages'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.additionalInformationForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const languages = this.additionalInformationForm.controls['languages'].value;
    const languagesNotes = this.additionalInformationForm.controls['languagesNotes'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      languages,
      languagesNotes
    };
    this.backendService.saveAdditionalInformation(data)
      .subscribe(() => this.toastr.success('Additional Interview Information', 'Data saved successfully'))
    this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
