import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-interviewer-information',
  templateUrl: './interviewer-information.component.html',
  styleUrls: ['./interviewer-information.component.scss']
})
export class InterviewerInformationComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public interviewerForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.interviewerForm = this.fb.group({
      interviewer: [ null ],
      interviewerReligion: [ null],
      interviewerDateOfBirth: [ null],
      numberOfInterviewsCompleted: [ null ],
      interviewerLocation: [ null ],
      interviewerOccupation: [ null],
      cameraPerson: [ null],
      interviewerTitle: [ null ],
      interviewerFirstName: [ null ],
      interviewerLastName: [ null ],
      interviewerSuffix: [ null ],
      interviewsCompleted: [ null ],
      designation: [ null ],
      background: [ null ],
      interviewerNativeLanguage: [ null ],
      interviewerRegionalLanguage: [ null ],
      connection: [ null],
      cameraTitle: [ null ],
      cameraFirstName: [ null ],
      cameraLastName: [ null ],
      cameraSuffix: [ null ],
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getInterviewer(this.interviewID).subscribe(interviewer => {
        this.interviewerForm = this.fb.group({
          interviewer: [ interviewer.interviewer ],
          interviewerReligion: [ interviewer.interviewerReligion],
          interviewerDateOfBirth: [ interviewer.interviewerDateOfBirth],
          numberOfInterviewsCompleted: [ interviewer.numberOfInterviewsCompleted ],
          interviewerLocation: [ interviewer.interviewerLocation ],
          interviewerOccupation: [ interviewer.interviewerOccupation],
          cameraPerson: [ interviewer.cameraPerson],
          interviewerTitle: [ interviewer.interviewerTitle ],
          interviewerFirstName: [ interviewer.interviewerFirstName ],
          interviewerLastName: [ interviewer.interviewerLastName ],
          interviewerSuffix: [ interviewer.interviewerSuffix ],
          interviewsCompleted: [ interviewer.interviewsCompleted ],
          designation: [ interviewer.designation ],
          interviewerNativeLanguage: [ interviewer.interviewerNativeLanguage ],
          interviewerRegionalLanguage: [ interviewer.interviewerRegionalLanguage ],
          connection: [ interviewer.connection],
          cameraTitle: [ interviewer.cameraTitle ],
          cameraFirstName: [ interviewer.cameraFirstName ],
          cameraLastName: [ interviewer.cameraLastName ],
          cameraSuffix: [ interviewer.cameraSuffix ],
          background: [ interviewer.background]
        });

        this.setValidators();
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.interviewerForm.controls['firstName'].setValidators(Validators.required);
      this.interviewerForm.controls['firstName'].updateValueAndValidity();
      this.interviewerForm.controls['lastName'].setValidators(Validators.required);
      this.interviewerForm.controls['lastName'].updateValueAndValidity();
      this.interviewerForm.controls['interviewer'].setValidators(Validators.required);
      this.interviewerForm.controls['interviewer'].updateValueAndValidity();
      this.interviewerForm.controls['interviewerDateOfBirth'].setValidators(Validators.required);
      this.interviewerForm.controls['interviewerDateOfBirth'].updateValueAndValidity();
      this.interviewerForm.controls['interviewerReligion'].setValidators(Validators.required);
      this.interviewerForm.controls['interviewerReligion'].updateValueAndValidity();
      this.interviewerForm.controls['interviewerOccupation'].setValidators(Validators.required);
      this.interviewerForm.controls['interviewerOccupation'].updateValueAndValidity();
      this.interviewerForm.controls['connection'].setValidators(Validators.required);
      this.interviewerForm.controls['connection'].updateValueAndValidity();
      this.interviewerForm.controls['interviewerNativeLanguage'].setValidators(Validators.required);
      this.interviewerForm.controls['interviewerNativeLanguage'].updateValueAndValidity();
      this.interviewerForm.controls['background'].setValidators(Validators.required);
      this.interviewerForm.controls['background'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.interviewerForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const interviewer = this.interviewerForm.controls['interviewer'].value;
    const interviewerReligion = this.interviewerForm.controls['interviewerReligion'].value;
    const interviewerDateOfBirth = this.interviewerForm.controls['interviewerDateOfBirth'].value;
    const numberOfInterviewsCompleted = this.interviewerForm.controls['numberOfInterviewsCompleted'].value;
    const interviewsCompleted = this.interviewerForm.controls['interviewsCompleted'].value;
    const interviewerLocation = this.interviewerForm.controls['interviewerLocation'].value;
    const interviewerOccupation = this.interviewerForm.controls['interviewerOccupation'].value;
    const cameraPerson = this.interviewerForm.controls['cameraPerson'].value;
    const interviewerTitle = this.interviewerForm.controls['interviewerTitle'].value;
    const interviewerFirstName = this.interviewerForm.controls['interviewerFirstName'].value;
    const interviewerLastName = this.interviewerForm.controls['interviewerLastName'].value;
    const interviewerSuffix = this.interviewerForm.controls['interviewerSuffix'].value;
    const designation = this.interviewerForm.controls['designation'].value;
    const background = this.interviewerForm.controls['background'].value;
    const interviewerNativeLanguage = this.interviewerForm.controls['interviewerNativeLanguage'].value;
    const interviewerRegionalLanguage = this.interviewerForm.controls['interviewerRegionalLanguage'].value;
    const connection = this.interviewerForm.controls['connection'].value;
    const cameraTitle = this.interviewerForm.controls['cameraTitle'].value;
    const cameraFirstName = this.interviewerForm.controls['cameraFirstName'].value;
    const cameraLastName = this.interviewerForm.controls['cameraLastName'].value;
    const cameraSuffix = this.interviewerForm.controls['cameraSuffix'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      interviewer,
      interviewerReligion,
      interviewerDateOfBirth,
      numberOfInterviewsCompleted,
      interviewsCompleted,
      interviewerLocation,
      interviewerOccupation,
      cameraPerson,
      interviewerTitle,
      interviewerFirstName,
      interviewerLastName,
      interviewerSuffix,
      designation,
      background,
      interviewerNativeLanguage,
      interviewerRegionalLanguage,
      connection,
      cameraTitle,
      cameraFirstName,
      cameraLastName,
      cameraSuffix
    };
    this.backendService.saveInterviewer(data)
      .subscribe(() => {
        this.toastr.success('Interviewer Information', 'Data saved successfully');
        this.formSaved.emit();
      })
  }

  get userType() {
    return this.authService.getUserType();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
