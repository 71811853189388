import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private api: ApiService) { }

  saveInterview(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/interview', data);
  }

  getInterviews(
    searchText = '',
    page = 0,
    perPage = 100,
    {
      statuses = [],
      priorities = [],
      assignments = []
    } = {},
    userType
  ): Observable<any> {
    const params = {
      searchText,
      statuses,
      priorities,
      assignments,
      page,
      perPage,
      userType
    };
    return this.api.get('/api/interview', params);
  }

  saveInterviewee(data: any): Observable<any> {
    var result = this.api.post('/api/interview/' + data.interviewID + '/interviewee', data);
    return result;
  }

  getInverview(interviewID: string): Observable<any> {
    return this.api.get('/api/interview/' + interviewID + '/interviewer');
  }

  createInterview(): Observable<any> {
    return this.api.post('/api/interview', {});
  }

  getInterview(permanentInterviewId: number): Observable<any> {
    return this.api.get('/api/interview/permanent-interview/' + permanentInterviewId);
  }

  getInterviewee(interviewID: string): Observable<any> {
    return this.api.get('/api/interview/' + interviewID + '/interviewee');
  }

  getInterviewer(interviewID: string): Observable<any> {
    return this.api.get('/api/interview/' + interviewID + '/interviewer');
  }

  saveInterviewer(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/interviewer', data);
  }

  getNarratorInformation(interviewID: string): Observable<any> {
    return this.api.get('/api/interview/' + interviewID + '/narrator');
  }

  getNarratorContactInformation(interviewID: string): Observable<any> {
    return this.api.get('/api/interview/' + interviewID + '/narrator-contact-information');
  }

  saveNarratorContactInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/narrator-contact-information', data);
  }

  saveNarrator(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/narrator', data);
  }

  saveFile(id: string, filename: string): Observable<any> {
    return this.api.postForm('/api/interview/' + id + '/' + filename, {});
  }

  getFiles(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/files');
  }

  delete(filename: string): Observable<any> {
    return this.api.delete('/api/interview/files/' + filename);
  }

  archive(id: string): Observable<any> {
    return this.api.put('/api/interview/' + id + '/archive', {});
  }

  rename(originalFilename: string, newFilename: string, location: string): Observable<any> {
    return this.api.put('/api/interview/files/rename/' + originalFilename, {
      newFilename,
      location
    });
  }

  getInterviewDate(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/dates');
  }

  saveInterviewDate(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/dates', data);
  }

  getIntervieweePermissions(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/interviewee-preferences');
  }

  saveIntervieweePermissions(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/interviewee-preferences', data);
  }

  getBirthplaceAddresss(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/birthplace-location');
  }

  saveBirthplaceAddresss(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/birthplace-location', data);
  }

  getResidenceBeforePartition(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/residence-before-partition');
  }

  saveResidenceBeforePartition(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/residence-before-partition', data);
  }

  getResidenceAfterPartition(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/residence-after-partition');
  }

  saveResidenceAfterPartition(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/residence-after-partition', data);
  }

  getMigrationDetails(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/migration-details');
  }

  saveMigrationDetails(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/migration-details', data);
  }

  getInterviewLocation(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/interview-location');
  }

  saveInterviewLocation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/interview-location', data);
  }

  getParentalInformation(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/parental-information');
  }

  saveParentalInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/parental-information', data);
  }

  getFamilyInformation(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/family-information');
  }

  saveFamilyInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/family-information', data);
  }

  getPseudonym(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/pseudonym');
  }

  savePseudonym(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/pseudonym', data);
  }

  getContactInformation(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/contact-information');
  }

  saveContactInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/contact-information', data);
  }

  getAdditionalInformation(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/additional-information');
  }

  saveAdditionalInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/additional-information', data);
  }

  getPhotoCaptions(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/photo-captions');
  }

  savePhotoCaptions(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/photo-captions', data);
  }

  getFacets(): Observable<any> {
    return this.api.get('/api/search/facets');
  }

  getCopyEditingAndCuration(interviewId: number): Observable<any> {
    return this.api.get(`/api/interview/${interviewId}/copy-editing-and-curation`);
  }

  saveCopyEditingAndCuration(data: any): Observable<any> {
    return this.api.post(`/api/interview/${data.interviewID}/copy-editing-and-curation`, data);
  }

  getAssignedToUsers(): Observable<any> {
    return this.api.get(`/api/interview/assigned-to-users`);
  }

  getArchivistForm(interviewId: number): Observable<any> {
    return this.api.get(`/api/interview/${interviewId}/archivist-form`);
  }

  saveArchivistForm(data: any): Observable<any> {
    return this.api.post(`/api/interview/${data.interviewID}/archivist-form`, data);
  }

  getInterviewReviewCourtesy(interviewId: number): Observable<any> {
    return this.api.get(`/api/interview/${interviewId}/interview-review-courtesy`);
  }

  saveInterviewReviewCourtesy(data: any): Observable<any> {
    return this.api.post(`/api/interview/${data.interviewID}/interview-review-courtesy`, data);
  }

  getInterviewReviewContent(interviewId: number): Observable<any> {
    return this.api.get(`/api/interview/${interviewId}/interview-review-content`);
  }

  saveInterviewReviewContent(data: any): Observable<any> {
    return this.api.post(`/api/interview/${data.interviewID}/interview-review-content`, data);
  }

  getInterviewReviewFilming(interviewId: number): Observable<any> {
    return this.api.get(`/api/interview/${interviewId}/interview-review-filming`);
  }

  saveInterviewReviewFilming(data: any): Observable<any> {
    return this.api.post(`/api/interview/${data.interviewID}/interview-review-filming`, data);
  }

  getInterviewReviewOther(interviewId: number): Observable<any> {
    return this.api.get(`/api/interview/${interviewId}/interview-review-other`);
  }

  saveInterviewReviewOther(data: any): Observable<any> {
    return this.api.post(`/api/interview/${data.interviewID}/interview-review-other`, data);
  }

  getArchivistContactInformation(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/archivist-contact-information');
  }

  saveArchivistContactInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/archivist-contact-information', data);
  }

  getAuditorContactInformation(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/auditor-contact-information');
  }

  saveAuditorContactInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/auditor-contact-information', data);
  }

  getInterviewReviewResponseReaction(id: number): Observable<any> {
    return this.api.get('/api/interview/' + id + '/interview-review-response-reaction');
  }

  saveInterviewReviewResponseReaction(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/interview-review-response-reaction', data);
  }

  getArchivistAbstractCuration(id: number): Observable<any> {
    return this.api.get('/api/interview/' + id + '/archivist-abstract-curation');
  }

  saveArchivistAbstractCuration(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/archivist-abstract-curation', data);
  }

  getArchivistOtherCuration(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/archivist-other-curation');
  }

  saveArchivistOtherCuration(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/archivist-other-curation', data);
  }

  getPostArchivingSponsorInformation(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/post-archiving-sponsor-information');
  }

  savePostArchivingSponsorInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/post-archiving-sponsor-information', data);
  }

  getTranscriptionInformation(id: number): Observable<any> {
    return this.api.get('/api/interview/' + id + '/transcription-information');
  }

  saveTranscriptionInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/transcription-information', data);
  }

  getArchivistStoryMap(id: number): Observable<any> {
    return this.api.get('/api/interview/' + id + '/archivist-storymap');
  }

  saveArchivistStoryMap(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/archivist-storymap', data);
  }

  getArchivingOther(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/archiving-other');
  }

  getSocialMediaCuration(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/social-media-curation');
  }

  saveSocialMediaCuration(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/social-media-curation', data);
  }

  getIntervieweeCommunications(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/interviewee-communications');
  }

  saveIntervieweeCommunications(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/interviewee-communications', data);
  }

  getInterviewerContactInformation(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/interviewer-contact-information');
  }

  saveInterviewerContactInformation(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/interviewer-contact-information', data);
  }

  getReleaseFormSubmission(id: string): Observable<any> {
    return this.api.get('/api/interview/' + id + '/release-form-submission');
  }

  saveReleaseFormSubmission(data: any): Observable<any> {
    return this.api.post('/api/interview/' + data.interviewID + '/release-form-submission', data);
  }

  deleteInterview(id: number): Observable<any> {
    return this.api.post('/api/interview/' + id + '/delete', {});
  }

  submit(id: string): Observable<any> {
    return this.api.post('/api/interview/' + id + '/submit', {});
  }

  exportURL(url: string, filename: string) {
    return this.api
        .getRemoteURLBlob(url)
        .pipe(tap(blob => saveAs(blob, filename)));
  }

  exportAllData() {
    return this.api
        .getBlob('/api/interview/export')
        .pipe(tap(blob => saveAs(blob, 'Archive_Database_' + moment().format('MM/DD/YYYY') + '.csv')));
  }

  exportStoryMap() {
    return this.api
        .getBlob('/api/interview/storymap-export')
        .pipe(tap(blob => saveAs(blob, 'Storymap_' + moment().format('MM/DD/YYYY') + '.csv')));
  }
}
