import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from '../../../services/backend.service';

@Component({
  selector: 'app-archivist-form',
  templateUrl: './archivist-form.component.html',
  styleUrls: ['./archivist-form.component.scss']
})
export class ArchivistFormComponent implements OnInit {
  public statusOptions = ['', 'Submission In Progress', 'Submitted/Received', 'Archiving In Progress', 'Archiving Completed', 'Copy-Editing In Progress', 'Copy-Edited', 'Auditing In Progress', 'Auditing Completed', 'Reviewing In Progress', 'Reviewed', 'Social Media Curation In Progress', 'Social Media Curated', 'Social Media Published'];
  public priorityOptions = ['', 'Deceased', 'Alive', 'Other'];
  public assignedToOptions = [{
    value: '',
    display: ''
  }];
  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public archivistForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private parserFormatter: NgbDateParserFormatter,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.archivistForm = this.fb.group({
      internalNotes: [],
      statusSelect: [],
      prioritySelect: [],
      assignedSelect: [],
      archivingComplete: [null ],
      dateOfInterviewArchived: [null ],
      infoMissing: [null ],
      otherMaterials: [null ],
      videoAudioReceived: [null ],
      lengthOfVideo: [null ],
      docsAddedToMaterial: [null ],
      filesCopiedToAWS: [null ],
      docsAddedToGlacier: [null ],
      photosAddedToGlacier: [null ],
      videosAddedToGlacier: [null ],
      backupDrive: [null ],
      confirmEmailSent: [null ],
      camera: [null ],
      tape: [null ],
      form: [null ],
      formFormat: [null ],
      otherNotes: [null ],
      archivistNotes: [null],
      originalFormat: [null],
      bRollReceived: [null],
      originalBRollFormat: [null],
      digitalSize: [null],
      auditAssignmentDate: [null],
      auditAssignmentAdditionalDate: [null],
      postForm: [null],
      summary: [null],
      summaryAdded: [null],
      releaseForm: [null],
      contactChecklist: [null],
      emailChecklist: [null]
    });

    this.backendService.getAssignedToUsers().subscribe(data => {
      this.assignedToOptions = [
        ...this.assignedToOptions,
        ...(data.map(user => {
          return {
            value: user.userID,
            display: user.fullName
          };
        }))
      ];
    });

    if (this.userType === 'archivist') {
      this.archivistForm.get('assignedSelect').disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getArchivistForm(this.interviewID).subscribe(data => {
        if (data) {
          this.archivistForm = this.fb.group({
            internalNotes: [data.internalNotes],
            statusSelect: [data.statusSelect],
            prioritySelect: [data.prioritySelect],
            assignedSelect: [data.assignedSelect],
            archivingComplete: [data.archivingComplete ],
            dateOfInterviewArchived: [this.parserFormatter.parse(data.dateOfInterviewArchived) ],
            infoMissing: [data.infoMissing ],
            otherMaterials: [data.otherMaterials ],
            videoAudioReceived: [data.videoAudioReceived ],
            lengthOfVideo: [data.lengthOfVideo ],
            docsAddedToMaterial: [data.docsAddedToMaterial ],
            filesCopiedToAWS: [data.filesCopiedToAWS ],
            docsAddedToGlacier: [data.docsAddedToGlacier ],
            photosAddedToGlacier: [data.photosAddedToGlacier ],
            videosAddedToGlacier: [data.videosAddedToGlacier ],
            backupDrive: [data.backupDrive ],
            confirmEmailSent: [data.confirmEmailSent ],
            camera: [data.camera ],
            tape: [data.tape ],
            form: [data.form ],
            formFormat: [data.formFormat ],
            otherNotes: [data.otherNotes ],
            archivistNotes: [data.archivistNotes],
            originalFormat: [data.originalFormat],
            bRollReceived: [data.bRollReceived],
            originalBRollFormat: [data.originalBRollFormat],
            digitalSize: [data.digitalSize],
            auditAssignmentDate: [data.auditAssignmentDate],
            auditAssignmentAdditionalDate: [data.auditAssignmentAdditionalDate],
            postForm: [data.postForm],
            summary: [data.summary],
            summaryAdded: [data.summaryAdded],
            releaseForm: [data.releaseForm],
            contactChecklist: [data.contactChecklist],
            emailChecklist: [data.emailChecklist]
          });

          this.updateValidators();
        }
      });
    }
  }

  updateValidators() {
      if (this.archivistForm.get('statusSelect').value === 'Auditing In Progress' && this.userType === 'archivist') {
        this.archivistForm.controls['auditAssignmentDate'].setValidators(Validators.required);
        this.archivistForm.controls['auditAssignmentDate'].updateValueAndValidity();
        this.archivistForm.controls['auditAssignmentAdditionalDate'].setValidators(Validators.required);
        this.archivistForm.controls['auditAssignmentAdditionalDate'].updateValueAndValidity();
      } else {
        this.archivistForm.controls['auditAssignmentDate'].clearValidators();
        this.archivistForm.controls['auditAssignmentDate'].updateValueAndValidity();
        this.archivistForm.controls['auditAssignmentAdditionalDate'].clearValidators();
        this.archivistForm.controls['auditAssignmentAdditionalDate'].updateValueAndValidity();
      }
  }

  saveForm() {
    if (!this.archivistForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const originalFormat = this.archivistForm.controls['originalFormat'].value;
    const bRollReceived = this.archivistForm.controls['bRollReceived'].value;
    const originalBRollFormat = this.archivistForm.controls['originalBRollFormat'].value;
    const digitalSize = this.archivistForm.controls['digitalSize'].value;
    const interviewID = this.interviewID;
    const internalNotes = this.archivistForm.controls['internalNotes'].value;
    const statusSelect = this.archivistForm.controls['statusSelect'].value;
    const prioritySelect = this.archivistForm.controls['prioritySelect'].value;
    const assignedSelect = this.archivistForm.controls['assignedSelect'].value;
    const archivingComplete = this.archivistForm.controls['archivingComplete'].value;
    const dateOfInterviewArchived = this.parserFormatter.format(this.archivistForm.controls['dateOfInterviewArchived'].value);
    const infoMissing = this.archivistForm.controls['infoMissing'].value;
    const otherMaterials = this.archivistForm.controls['otherMaterials'].value;
    const videoAudioReceived = this.archivistForm.controls['videoAudioReceived'].value;
    const lengthOfVideo = this.archivistForm.controls['lengthOfVideo'].value;
    const docsAddedToMaterial = this.archivistForm.controls['docsAddedToMaterial'].value;
    const filesCopiedToAWS = this.archivistForm.controls['filesCopiedToAWS'].value;
    const docsAddedToGlacier = this.archivistForm.controls['docsAddedToGlacier'].value;
    const photosAddedToGlacier = this.archivistForm.controls['photosAddedToGlacier'].value;
    const videosAddedToGlacier = this.archivistForm.controls['videosAddedToGlacier'].value;
    const backupDrive = this.archivistForm.controls['backupDrive'].value;
    const confirmEmailSent = this.archivistForm.controls['confirmEmailSent'].value;
    const camera = this.archivistForm.controls['camera'].value;
    const tape = this.archivistForm.controls['tape'].value;
    const form = this.archivistForm.controls['form'].value;
    const formFormat = this.archivistForm.controls['formFormat'].value;
    const otherNotes = this.archivistForm.controls['otherNotes'].value;
    const archivistNotes = this.archivistForm.controls['archivistNotes'].value;
    const auditAssignmentDate = this.archivistForm.controls['auditAssignmentDate'].value;
    const auditAssignmentAdditionalDate = this.archivistForm.controls['auditAssignmentAdditionalDate'].value;
    const postForm = this.archivistForm.controls['postForm'].value;
    const summary = this.archivistForm.controls['summary'].value;
    const summaryAdded = this.archivistForm.controls['summaryAdded'].value;
    const releaseForm = this.archivistForm.controls['releaseForm'].value;
    const contactChecklist = this.archivistForm.controls['contactChecklist'].value;
    const emailChecklist = this.archivistForm.controls['emailChecklist'].value;

    let data = {
      interviewID,
      archivingComplete,
      dateOfInterviewArchived,
      infoMissing,
      otherMaterials,
      videoAudioReceived,
      lengthOfVideo,
      docsAddedToMaterial,
      filesCopiedToAWS,
      docsAddedToGlacier,
      photosAddedToGlacier,
      videosAddedToGlacier,
      backupDrive,
      confirmEmailSent,
      camera,
      tape,
      form,
      formFormat,
      otherNotes,
      archivistNotes,
      originalFormat,
      bRollReceived,
      originalBRollFormat,
      digitalSize,
      internalNotes,
      statusSelect,
      prioritySelect,
      assignedSelect,
      auditAssignmentDate,
      auditAssignmentAdditionalDate,
      postForm,
      summary,
      summaryAdded,
      releaseForm,
      contactChecklist,
      emailChecklist
    };
    this.backendService.saveArchivistForm(data)
      .subscribe(() => this.toastr.success('Archivist Information', 'Data saved successfully'));
    this.formSaved.emit();
  }

  get userType(): string {
    return this.authService.getUserType();
  }

  delete() {
    if (confirm('Are you sure to delete this interview and it\'s associated interview files?')) {
      this.backendService.deleteInterview(this.interviewID)
      .subscribe(() => {
        this.toastr.success('Interview deleted successfully');
        this.router.navigateByUrl('/');
      });
    }
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
