import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { throwError, Observable } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    private authenticationService: AuthService;

    constructor(
        private injector: Injector,
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authenticationService = this.injector.get(AuthService, undefined);
        return next.handle(request).pipe(catchError(err => {
            return this.refresh(err, request, next);
        }));
    }

    private refresh(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('refresh');

        // If no token or not 401, just continue throwing original error
        if (!token || (err.status !== 401 && err.status !== 403)) {
            return throwError(err);
        }

        if (err.status === 403) {
            this.router.navigate(['']);
        }

        return this.authenticationService.refreshToken(token).pipe(
            switchMap((response: any) => next.handle(this.addToken(request, response.JWT))),
            // The first event indicates the request has been dispatched to the backend.
            // The second event is the response.
            take(2)
        );
    }

    addToken<T>(req: HttpRequest<T>, token: string) {
        if (token) {
            return req.clone({
                setHeaders: {
                    Authorization: `${token}`
                },
            });
        }
        return req;
    }
}
