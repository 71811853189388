import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-interview-location',
  templateUrl: './interview-location.component.html',
  styleUrls: ['./interview-location.component.scss']
})
export class InterviewLocationComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public interviewLocationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.interviewLocationForm = this.fb.group({
      inPersonOrRemote: [ null ],
      houseNumber: [ null ],
      street: [ null ],
      city: [ null ],
      district: [ null ],
      state: [ null ],
      country: [ null ],
      postalCode: [ null ],
      town: [ null ],
      latitudeInterviewLocation: [ null ],
      longitudeInterviewLocation: [ null ],
      interviewLocation: [null ],
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getInterviewLocation(this.interviewID).subscribe(location => {
        if (location) {
          this.interviewLocationForm = this.fb.group({
            inPersonOrRemote: [ location.inPersonOrRemote ],
            houseNumber: [ location.houseNumber ],
            street: [ location.street ],
            city: [ location.city ],
            district: [ location.district ],
            state: [ location.state ],
            country: [ location.country ],
            postalCode: [ location.postalCode ],
            town: [ location.town ],
            latitudeInterviewLocation: [ location.latitudeInterviewLocation ],
            longitudeInterviewLocation: [ location.longitudeInterviewLocation ],
            interviewLocation: [ location.interviewLocation ],
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.interviewLocationForm.controls['inPersonOrRemote'].setValidators(Validators.required);
      this.interviewLocationForm.controls['inPersonOrRemote'].updateValueAndValidity();
      this.interviewLocationForm.controls['city'].setValidators(Validators.required);
      this.interviewLocationForm.controls['city'].updateValueAndValidity();
      this.interviewLocationForm.controls['state'].setValidators(Validators.required);
      this.interviewLocationForm.controls['state'].updateValueAndValidity();
      this.interviewLocationForm.controls['country'].setValidators(Validators.required);
      this.interviewLocationForm.controls['country'].updateValueAndValidity();
      this.interviewLocationForm.controls['town'].setValidators(Validators.required);
      this.interviewLocationForm.controls['town'].updateValueAndValidity();
      this.interviewLocationForm.controls['latitude'].setValidators(Validators.required);
      this.interviewLocationForm.controls['latitude'].updateValueAndValidity();
      this.interviewLocationForm.controls['longitude'].setValidators(Validators.required);
      this.interviewLocationForm.controls['longitude'].updateValueAndValidity();
      this.interviewLocationForm.controls['interviewLocation'].setValidators(Validators.required);
      this.interviewLocationForm.controls['interviewLocation'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.interviewLocationForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const houseNumber = this.interviewLocationForm.controls['houseNumber'].value;
    const street = this.interviewLocationForm.controls['street'].value;
    const city = this.interviewLocationForm.controls['city'].value;
    const district = this.interviewLocationForm.controls['district'].value;
    const state = this.interviewLocationForm.controls['state'].value;
    const country = this.interviewLocationForm.controls['country'].value;
    const postalCode = this.interviewLocationForm.controls['postalCode'].value;
    const town = this.interviewLocationForm.controls['town'].value;
    const latitudeInterviewLocation = this.interviewLocationForm.controls['latitudeInterviewLocation'].value;
    const longitudeInterviewLocation = this.interviewLocationForm.controls['longitudeInterviewLocation'].value;
    const interviewLocation = this.interviewLocationForm.controls['interviewLocation'].value;
    const inPersonOrRemote = this.interviewLocationForm.controls['inPersonOrRemote'].value;
    const interviewID = this.interviewID;
    let data = { interviewID, houseNumber, street, city, district, state, country, postalCode, town, latitudeInterviewLocation, longitudeInterviewLocation, interviewLocation, inPersonOrRemote };
    this.backendService.saveInterviewLocation(data)
      .subscribe(() => this.toastr.success('Interview Location', 'Data saved successfully'))
    this.formSaved.emit();
  }

  get userType() {
    return this.authService.getUserType();
  }
}
