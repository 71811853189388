import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchText: string;
  private selectedStatuses: string[] = [];
  private selectedAssignments: string[] = [];
  private sortName: string;
  private sortOrder: boolean;

  constructor() { }

  clearAll() {
    this.searchText = null;
    this.selectedStatuses = [];
    this.selectedAssignments = [];
    this.sortName = null;
    this.sortOrder = null;
  }

  updateStatuses(updatedStatuses) {
    this.selectedStatuses = updatedStatuses;
  }

  updateAssignments(updatedAssignments) {
    this.selectedAssignments = updatedAssignments;
  }

  updateSearchText(updatedSearchText) {
    this.searchText = updatedSearchText;
  }

  updateSort(updateSortName, updateSortOrder) {
    this.sortName = updateSortName;
    this.sortOrder = updateSortOrder;
  }

  loadFromSearchService() {
    return {
      searchText: this.searchText,
      selectedStatuses: this.selectedStatuses,
      selectedAssignments: this.selectedAssignments,
      sortName: this.sortName,
      sortOrder: this.sortOrder
    }
  }
}
