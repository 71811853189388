import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import wordsCount from 'words-count';

@Component({
  selector: 'app-archivist-abstract-curation',
  templateUrl: './archivist-abstract-curation.component.html',
  styleUrls: ['./archivist-abstract-curation.component.scss']
})
export class ArchivistAbstractCurationComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public abstractCurationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.abstractCurationForm = this.fb.group({
      rawSummary: [null],
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getArchivistAbstractCuration(this.interviewID).subscribe(information => {
        if (information) {
          this.abstractCurationForm = this.fb.group({
            rawSummary: [information.rawSummary ],
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.abstractCurationForm.controls['summary'].setValidators(Validators.required);
      this.abstractCurationForm.controls['summary'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.abstractCurationForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const rawSummary = this.abstractCurationForm.controls['rawSummary'].value;
    if (wordsCount(rawSummary) < 600 && this.userType === 'interviewer') {
      this.toastr.error('The interview summary/abstract must be at least 600 words long.');
      return;
    }

    const interviewID = this.interviewID;
    let data = {
      interviewID,
      rawSummary
    };
    this.backendService.saveArchivistAbstractCuration(data)
      .subscribe(() => this.toastr.success('Interview Summary/Abstract', 'Data saved successfully'))
    this.formSaved.emit();
  }

  get userType(): string {
    return this.authService.getUserType();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
