import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RecordTableColumn } from 'src/app/model/recordTableColumn';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-records-table',
  templateUrl: './records-table.component.html',
  styleUrls: ['./records-table.component.scss']
})
export class RecordsTableComponent implements OnChanges {
  @Input() data = [];
  @Input() columns: RecordTableColumn[];
  @Input() currentPage: number = 0;
  @Input() isLoading: boolean = false;
  public sortType: boolean = true
  public widths = ['100', '180', '140', '80', '90', '62', '200', '350', '400', '150', '100', '250', '200', '220', '400', '400', '150', '150', '170', '200', '220', '180', '300'];
  private recordsPerPage = 100;
  public visibleData = [];

  constructor(
    private router: Router,
    private searchService: SearchService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    const sortName = this.searchService.loadFromSearchService().sortName;
    const sortOrder = this.searchService.loadFromSearchService().sortOrder;
    if (sortName) {
      this.sortProduct(sortName, sortOrder);
    }
    this.renderData();
  }

  renderData() {
    this.visibleData = this.data.slice(this.currentPage * this.recordsPerPage, this.currentPage * this.recordsPerPage + this.recordsPerPage);
  }

  redirectToInterviewPage(tId: number, PAId: number, id: number) {
    if (tId) {
      this.router.navigate(['/submission-form/t/' + tId]);
    } else if (PAId) {
      this.router.navigate(['/submission-form/pa/' + PAId]);
    } else {
      this.router.navigate(['/submission-form/' + id]);
    }
  }

  sort(name) {
    this.searchService.updateSort(name, this.sortType);
    this.sortProduct(name, this.sortType);
    this.renderData();
  }

  sortProduct<T>(propName, order: boolean): void {
    this.data.sort((a, b) => {
      if (a[propName] < b[propName])
        return -1;
      if (a[propName] > b[propName])
        return 1;
      return 0; 
    });
    if (order) {
      this.data.reverse();
      this.sortType = false;
    } else {
      this.sortType = true;
    }
  } 
}
