import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-birthplace-location',
  templateUrl: './birthplace-location.component.html',
  styleUrls: ['./birthplace-location.component.scss']
})
export class BirthplaceLocationComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public birthplaceAddressForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.birthplaceAddressForm = this.fb.group({
      houseNumberBirthplace: [null],
      streetBirthplace: [null],
      cityBirthplace: [null ],
      stateBirthplace: [null ],
      districtBirthplace: [null],
      postalCodeBirthplace: [null],
      countryBirthplace: [null ],
      villageBirthplace: [null],
      latitudeBirthplace: [null ],
      longitudeBirthplace: [null ],
      placeOfBirth: [null ],
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getBirthplaceAddresss(this.interviewID).subscribe(birthplace => {
        if (birthplace) {
          this.birthplaceAddressForm = this.fb.group({
            houseNumberBirthplace: [ birthplace.houseNumberBirthplace ],
            streetBirthplace: [ birthplace.streetBirthplace ],
            cityBirthplace: [ birthplace.cityBirthplace ],
            stateBirthplace: [ birthplace.stateBirthplace ],
            districtBirthplace: [ birthplace.districtBirthplace ],
            postalCodeBirthplace: [ birthplace.postalCodeBirthplace ],
            countryBirthplace: [ birthplace.countryBirthplace ],
            villageBirthplace: [ birthplace.villageBirthplace ],
            latitudeBirthplace: [ birthplace.latitudeBirthplace ],
            longitudeBirthplace: [ birthplace.longitudeBirthplace ],
            placeOfBirth: [birthplace.placeOfBirth ],
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.birthplaceAddressForm.controls['city'].setValidators(Validators.required);
      this.birthplaceAddressForm.controls['city'].updateValueAndValidity();
      this.birthplaceAddressForm.controls['state'].setValidators(Validators.required);
      this.birthplaceAddressForm.controls['state'].updateValueAndValidity();
      this.birthplaceAddressForm.controls['country'].setValidators(Validators.required);
      this.birthplaceAddressForm.controls['country'].updateValueAndValidity();
      this.birthplaceAddressForm.controls['latitude'].setValidators(Validators.required);
      this.birthplaceAddressForm.controls['latitude'].updateValueAndValidity();
      this.birthplaceAddressForm.controls['longitude'].setValidators(Validators.required);
      this.birthplaceAddressForm.controls['longitude'].updateValueAndValidity();
      this.birthplaceAddressForm.controls['placeOfBirth'].setValidators(Validators.required);
      this.birthplaceAddressForm.controls['placeOfBirth'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.birthplaceAddressForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const houseNumberBirthplace = this.birthplaceAddressForm.controls['houseNumberBirthplace'].value;
    const streetBirthplace = this.birthplaceAddressForm.controls['streetBirthplace'].value;
    const cityBirthplace = this.birthplaceAddressForm.controls['cityBirthplace'].value;
    const stateBirthplace = this.birthplaceAddressForm.controls['stateBirthplace'].value;
    const districtBirthplace = this.birthplaceAddressForm.controls['districtBirthplace'].value;
    const postalCodeBirthplace = this.birthplaceAddressForm.controls['postalCodeBirthplace'].value;
    const countryBirthplace = this.birthplaceAddressForm.controls['countryBirthplace'].value;
    const villageBirthplace = this.birthplaceAddressForm.controls['villageBirthplace'].value;
    const latitudeBirthplace = this.birthplaceAddressForm.controls['latitudeBirthplace'].value;
    const longitudeBirthplace = this.birthplaceAddressForm.controls['longitudeBirthplace'].value;
    const placeOfBirth = this.birthplaceAddressForm.controls['placeOfBirth'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      houseNumberBirthplace,
      streetBirthplace,
      cityBirthplace,
      stateBirthplace,
      districtBirthplace,
      postalCodeBirthplace,
      countryBirthplace,
      villageBirthplace,
      latitudeBirthplace,
      longitudeBirthplace,
      placeOfBirth
    };
    this.backendService.saveBirthplaceAddresss(data)
      .subscribe(() => this.toastr.success('Birthplace Location', 'Data saved successfully'))
    this.formSaved.emit();
  }

  get userType() {
    return this.authService.getUserType();
  }

}
