import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-residence-after-partition',
  templateUrl: './residence-after-partition.component.html',
  styleUrls: ['./residence-after-partition.component.scss']
})
export class ResidenceAfterPartitionComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public residenceAfterPartitionForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.residenceAfterPartitionForm = this.fb.group({
      migrationQuestion: [null],
      houseNumberAfterPartition: [null],
      streetAfterPartition: [null],
      cityAfterPartition: [null],
      stateAfterPartition: [null],
      districtAfterPartition: [null],
      postalCodeAfterPartition: [null],
      countryAfterPartition: [null],
      villageAfterPartition: [null],
      migratedToLatitude: [null],
      migratedToLongitude: [null],
      migratedToAddress: [null]
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getResidenceAfterPartition(this.interviewID).subscribe(residence => {
        if (residence) {
          this.residenceAfterPartitionForm = this.fb.group({
            migrationQuestion: [ residence.migrationQuestion ],
            houseNumberAfterPartition: [ residence.houseNumberAfterPartition ],
            streetAfterPartition: [ residence.streetAfterPartition ],
            cityAfterPartition: [ residence.cityAfterPartition ],
            stateAfterPartition: [ residence.stateAfterPartition ],
            districtAfterPartition: [ residence.districtAfterPartition ],
            postalCodeAfterPartition: [ residence.postalCodeAfterPartition ],
            countryAfterPartition: [ residence.countryAfterPartition ],
            villageAfterPartition: [ residence.villageAfterPartition ],
            migratedToLatitude: [ residence.migratedToLatitude ],
            migratedToLongitude: [ residence.migratedToLongitude ],
            migratedToAddress: [ residence.migratedToAddress ]
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.residenceAfterPartitionForm.controls['migrationQuestion'].setValidators(Validators.required);
      this.residenceAfterPartitionForm.controls['migrationQuestion'].updateValueAndValidity();
      this.residenceAfterPartitionForm.controls['city'].setValidators(Validators.required);
      this.residenceAfterPartitionForm.controls['city'].updateValueAndValidity();
      this.residenceAfterPartitionForm.controls['state'].setValidators(Validators.required);
      this.residenceAfterPartitionForm.controls['state'].updateValueAndValidity();
      this.residenceAfterPartitionForm.controls['country'].setValidators(Validators.required);
      this.residenceAfterPartitionForm.controls['country'].updateValueAndValidity();
      this.residenceAfterPartitionForm.controls['latitude'].setValidators(Validators.required);
      this.residenceAfterPartitionForm.controls['latitude'].updateValueAndValidity();
      this.residenceAfterPartitionForm.controls['longitude'].setValidators(Validators.required);
      this.residenceAfterPartitionForm.controls['longitude'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.residenceAfterPartitionForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const migrationQuestion = this.residenceAfterPartitionForm.controls['migrationQuestion'].value;
    const houseNumberAfterPartition = this.residenceAfterPartitionForm.controls['houseNumberAfterPartition'].value;
    const streetAfterPartition = this.residenceAfterPartitionForm.controls['streetAfterPartition'].value;
    const cityAfterPartition = this.residenceAfterPartitionForm.controls['cityAfterPartition'].value;
    const stateAfterPartition = this.residenceAfterPartitionForm.controls['stateAfterPartition'].value;
    const districtAfterPartition = this.residenceAfterPartitionForm.controls['districtAfterPartition'].value;
    const postalCodeAfterPartition = this.residenceAfterPartitionForm.controls['postalCodeAfterPartition'].value;
    const countryAfterPartition = this.residenceAfterPartitionForm.controls['countryAfterPartition'].value;
    const villageAfterPartition = this.residenceAfterPartitionForm.controls['villageAfterPartition'].value;
    const migratedToLatitude = this.residenceAfterPartitionForm.controls['migratedToLatitude'].value;
    const migratedToLongitude = this.residenceAfterPartitionForm.controls['migratedToLongitude'].value;
    const migratedToAddress = this.residenceAfterPartitionForm.controls['migratedToAddress'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      migrationQuestion,
      houseNumberAfterPartition,
      streetAfterPartition,
      cityAfterPartition,
      stateAfterPartition,
      districtAfterPartition,
      postalCodeAfterPartition,
      countryAfterPartition,
      villageAfterPartition,
      migratedToLatitude,
      migratedToLongitude,
      migratedToAddress,
    };
    this.backendService.saveResidenceAfterPartition(data)
    .subscribe(() => this.toastr.success('Interviewee Residence After Partition', 'Data saved successfully'))
    this.formSaved.emit();
  }

  get userType() {
    return this.authService.getUserType();
  }
}
