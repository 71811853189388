import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  public loginForm: FormGroup;
  public showForgotPasswordForm = false;
  public forgotEmail = '';

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: [null, Validators.required ],
      password: [null, Validators.required ]
    });
  }

  onSubmit() {
    const email = this.loginForm.controls['email'].value;
    const password = this.loginForm.controls['password'].value;
    this.authService.login(email, password).subscribe((response) => {
      if (response.code === 'auth/success') {
        this.toastr.success('Login', 'Successfully logged in');
      } else if (response.code === 'auth/email-verified-failure') {
        this.toastr.error('Another verification link has been sent to your email.', 'Your email is not yet verified')
      } else {
        this.toastr.error(response.error, 'Log In Error');
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // setForgotEmail(event) {
  //   this.forgotEmail = event.srcElement.value;
  // }

  // sendLink() {
  //   if (this.forgotEmail) {
  //     // this.backendService.sendResetLink(this.forgotEmail)
  //     //   .subscribe(() => this.toastr.success('Reset Password Link sent!', 'Reset Password'))
  //   } else {
  //     // this.toastr.error('Please enter a valid email', 'Invalid Email');
  //   }
  // }
  
}
