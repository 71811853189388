<div class="form-data">
    <div class="d-flex" *ngFor="let file of files">
        <div class="form-group" style="width:70%">
            <div style="margin-bottom:15px">
                <span *ngIf="userType !== 'interviewer'" style="color:black;font-size:14px">{{ file.location }}/</span>
                <span *ngIf="file.prefix">{{ file.prefix }}</span>
                <input #filename type="text" class="form-input" style="display:inline-block;width:380px" [name]="file.filename" [id]="file.filename" [value]="file.displayName">
                <button (click)="rename(file.filename, filename.value, file.location)" class="save-button" style="width:100px;margin:2px 20px 0 0;height:40px;outline:none">Rename</button>

                <button  *ngIf="file.fileType === 'audio-video'"  class="save-button" style="width:100px;margin:2px 20px 0 0;height:40px;outline:none" (click)="setvideo(file.signedURL, file.filename);">Play Video</button>
              <button  *ngIf="file.fileType === 'photo'"  class="save-button" style="width:100px;margin:2px 20px 0 0;height:40px;outline:none" (click)="viewPhoto(file.signedURL, file.filename);">View Photo</button>

            </div>
        </div>


    </div>
</div>
<div uploadxDrop style="text-align:center">
  <div class="control">
    <span>
      <input #myInput id="files" type="file" [uploadx]="options" (state)="onStateChanged($event)" [control]="control"/>
    </span>
  </div>
  <div *ngFor="let item of uploads$ | async" style="text-align:center">
    <span>{{ item.progress }}% </span>
    <span>{{ item.status }}</span>
  </div>
</div>
