import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-document-files',
  templateUrl: './document-files.component.html',
  styleUrls: ['./document-files.component.scss'],
})
export class DocumentFilesComponent {
  @Input() interviewID: string;

  public fileType = 'release';

  constructor(
    private authService: AuthService,
  ) { }

  get userType() {
    return this.authService.getUserType();
  }
}
