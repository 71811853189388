import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { RecordsSearchComponent } from './records-search/records-search.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { SubmissionLayoutComponent } from './forms/submission-layout/submission-layout.component';
import { SubmissionFormComponent } from './submission-form/submission-form.component';
import { OtherFilesComponent } from './forms/other-files/other-files.component';
import { ArchivistFormComponent } from './forms/archivist/archivist-form.component';
import { LoginComponent } from './login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { HttpClientModule } from '@angular/common/http';
import { IntervieweeInformationComponent } from './forms/interviewee-information/interviewee-information.component';
import { InterviewerInformationComponent } from './forms/interviewer-information/interviewer-information.component';
import { NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { InterviewDateComponent } from './forms/interview-date/interview-date.component';
import { InterviewLocationComponent } from './forms/interview-location/interview-location.component';
import { IntervieweePreferencesComponent } from './forms/interviewee-preferences/interviewee-preferences.component';
import { BirthplaceLocationComponent } from './forms/birthplace-location/birthplace-location.component';
import { ResidenceBeforePartitionComponent } from './forms/residence-before-partition/residence-before-partition.component';
import { ResidenceAfterPartitionComponent } from './forms/residence-after-partition/residence-after-partition.component';
import { MigrationDetailsComponent } from './forms/migration-details/migration-details.component';
import { ParentalInformationComponent } from './forms/parental-information/parental-information.component';
import { FamilyInformationComponent } from './forms/family-information/family-information.component';
import { ContactInformationComponent } from './forms/contact-information/contact-information.component';
import { AdditionalInformationComponent } from './forms/additional-information/additional-information.component';
import { CopyEditingAndCurationComponent } from './forms/copy-editing-and-curation/copy-editing-and-curation.component';
import { ArchivistContactInformationComponent } from './forms/archivist-contact-information/archivist-contact-information.component';
import { InterviewReviewContentComponent } from './forms/interview-review-content/interview-review-content.component';
import { InterviewReviewCourtesyComponent } from './forms/interview-review-courtesy/interview-review-courtesy.component';
import { InterviewReviewOtherComponent } from './forms/interview-review-other/interview-review-other.component';
import { InterviewReviewFilmingComponent } from './forms/interview-review-filming/interview-review-filming.component';
import { InterviewReviewResponseReactionComponent } from './forms/interview-review-response-reaction/interview-review-response-reaction.component';
import { ArchivistAbstractCurationComponent } from './forms/archivist-abstract-curation/archivist-abstract-curation.component';
import { SocialMediaCurationComponent } from './forms/social-media-curation/social-media-curation.component';
import { IntervieweeCommunicationsComponent } from './forms/interviewee-communications/interviewee-communications.component';
import { PostArchivingSponsorInformationComponent } from './forms/post-archiving-sponsor-information/post-archiving-sponsor-information.component';
import { InterviewerContactInformationComponent } from './forms/interviewer-contact-information/interviewer-contact-information.component';
import { ReleaseFormSubmissionComponent } from './forms/release-form-submission/release-form-submission.component';
import { ArchivistStoryMapComponent } from './forms/archivist-storymap/archivist-storymap.component';
import { NarratorInformationComponent } from './forms/narrator-information/narrator-information.component';
import { NarratorContactInformationComponent } from './forms/narrator-contact-information/narrator-contact-information.component';
import { UploadxModule } from 'ngx-uploadx';
import { AudioVideoFilesComponent } from './forms/audio-video-files/audio-video-files.component';
import { PhotoFilesComponent } from './forms/photo-files/photo-files.component';
import { DocumentFilesComponent } from './forms/document-files/document-files.component';
import { AppTranscriptInformationComponent } from './forms/transcript-information/transcript-information.component';
import { PseudonymComponent } from './forms/pseudonym/pseudonym.component';
import { AutosizeModule } from 'ngx-autosize';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { CustomDateParserFormatter } from '../shared/providers/datepicker-adapter';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    RecordsSearchComponent,
    SubmissionLayoutComponent,
    IntervieweeInformationComponent,
    SubmissionFormComponent,
    AudioVideoFilesComponent,
    PhotoFilesComponent,
    DocumentFilesComponent,
    OtherFilesComponent,
    ArchivistFormComponent,
    AppTranscriptInformationComponent,
    LoginComponent,
    RegisterComponent,
    InterviewerInformationComponent,
    InterviewDateComponent,
    InterviewLocationComponent,
    IntervieweePreferencesComponent,
    BirthplaceLocationComponent,
    ResidenceBeforePartitionComponent,
    ResidenceAfterPartitionComponent,
    MigrationDetailsComponent,
    ParentalInformationComponent,
    FamilyInformationComponent,
    ContactInformationComponent,
    AdditionalInformationComponent,
    CopyEditingAndCurationComponent,
    NarratorInformationComponent,
    NarratorContactInformationComponent,
    ArchivistContactInformationComponent,
    InterviewReviewContentComponent,
    InterviewReviewCourtesyComponent,
    InterviewReviewOtherComponent,
    InterviewReviewFilmingComponent,
    InterviewReviewResponseReactionComponent,
    ArchivistAbstractCurationComponent,
    SocialMediaCurationComponent,
    IntervieweeCommunicationsComponent,
    PostArchivingSponsorInformationComponent,
    InterviewerContactInformationComponent,
    ReleaseFormSubmissionComponent,
    ArchivistStoryMapComponent,
    PseudonymComponent,
    FileUploaderComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule,
    ToastrModule.forRoot(),
    UploadxModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbDatepickerModule,
    AutosizeModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent
  ],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    }
  ]
})
export class CoreModule { }
