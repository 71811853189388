import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-social-media-curation',
  templateUrl: './social-media-curation.component.html',
  styleUrls: ['./social-media-curation.component.scss']
})
export class SocialMediaCurationComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public socialMediaCurationForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private toastr: ToastrService,
              private parserFormatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    this.socialMediaCurationForm = this.fb.group({
      contentCurator: [null ],
      contentFacebookURL: [null],
      contentTwitterHandle: [null],
      contentInstagramHandle: [null],
      contentLinkedInProfile: [null],
      contentTikTokProfile: [null],
      liveFacebookPosted: [null],
      linkToFacebookPosted: [null],
      liveInstagramPosted: [null],
      linkToInstagramPosted: [null],
      liveLinkedInPosted: [null],
      linkToLinkedInPosted: [null],
      liveTwitterPosted: [null],
      linkToTwitterPosted: [null],
      dateOfPostedContent: [null ],
      postedOnSocialMedia: [null],
      postedToFacebook: [null],
      socialMediaNotes: [null],
      emailNotification: [null ],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getSocialMediaCuration(this.interviewID).subscribe(information => {
        if (information) {
          this.socialMediaCurationForm = this.fb.group({
            contentCurator: [information.contentCurator ],
            contentFacebookURL: [information.contentFacebookURL ],
            contentTwitterHandle: [information.contentTwitterHandle ],
            contentInstagramHandle: [information.contentInstagramHandle ],
            contentLinkedInProfile: [information.contentLinkedInProfile ],
            contentTikTokProfile: [information.contentTikTokProfile],
            liveFacebookPosted: [information.liveFacebookPosted],
            linkToFacebookPosted: [information.linkToFacebookPosted],
            liveInstagramPosted: [information.liveInstagramPosted],
            linkToInstagramPosted: [information.linkToInstagramPosted],
            liveLinkedInPosted: [information.liveLinkedInPosted],
            linkToLinkedInPosted: [information.linkToLinkedInPosted],
            liveTwitterPosted: [information.liveTwitterPosted],
            linkToTwitterPosted: [information.linkToTwitterPosted],
            dateOfPostedContent: [this.parserFormatter.parse(information.dateOfPostedContent)],
            postedOnSocialMedia: [information.postedOnSocialMedia],
            postedToFacebook: [information.postedToFacebook],
            socialMediaNotes: [information.socialMediaNotes],
            emailNotification: [information.emailNotification],
          });
        }
      });
    }
  }

  saveForm() {
    const contentCurator = this.socialMediaCurationForm.controls['contentCurator'].value;
    const contentFacebookURL = this.socialMediaCurationForm.controls['contentFacebookURL'].value;
    const contentTwitterHandle = this.socialMediaCurationForm.controls['contentTwitterHandle'].value;
    const contentInstagramHandle = this.socialMediaCurationForm.controls['contentInstagramHandle'].value;
    const contentLinkedInProfile = this.socialMediaCurationForm.controls['contentLinkedInProfile'].value;
    const contentTikTokProfile = this.socialMediaCurationForm.controls['contentTikTokProfile'].value;
    const liveFacebookPosted = this.socialMediaCurationForm.controls['liveFacebookPosted'].value;
    const linkToFacebookPosted = this.socialMediaCurationForm.controls['linkToFacebookPosted'].value;
    const liveInstagramPosted = this.socialMediaCurationForm.controls['liveInstagramPosted'].value;
    const linkToInstagramPosted = this.socialMediaCurationForm.controls['linkToInstagramPosted'].value;
    const liveLinkedInPosted = this.socialMediaCurationForm.controls['liveLinkedInPosted'].value;
    const linkToLinkedInPosted = this.socialMediaCurationForm.controls['linkToLinkedInPosted'].value;
    const liveTwitterPosted = this.socialMediaCurationForm.controls['liveTwitterPosted'].value;
    const linkToTwitterPosted = this.socialMediaCurationForm.controls['linkToTwitterPosted'].value;
    const dateOfPostedContent = this.parserFormatter.format(this.socialMediaCurationForm.controls['dateOfPostedContent'].value);
    const postedOnSocialMedia = this.socialMediaCurationForm.controls['postedOnSocialMedia'].value;
    const postedToFacebook = this.socialMediaCurationForm.controls['postedToFacebook'].value;
    const socialMediaNotes = this.socialMediaCurationForm.controls['socialMediaNotes'].value;
    const emailNotification = this.socialMediaCurationForm.controls['emailNotification'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID, contentCurator, contentFacebookURL, contentTwitterHandle, contentInstagramHandle, contentLinkedInProfile,
      contentTikTokProfile, liveFacebookPosted, linkToFacebookPosted, liveInstagramPosted, linkToInstagramPosted,
      liveLinkedInPosted, linkToLinkedInPosted, liveTwitterPosted, linkToTwitterPosted, dateOfPostedContent,
      postedOnSocialMedia, postedToFacebook, socialMediaNotes, emailNotification
    };
    this.backendService.saveSocialMediaCuration(data)
      .subscribe(() => this.toastr.success('Social Media Curation Information', 'Data saved successfully'))
    this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
