import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-archivist-contact-information',
  templateUrl: './archivist-contact-information.component.html',
  styleUrls: ['./archivist-contact-information.component.scss']
})
export class ArchivistContactInformationComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public archivistContactInformationForm: FormGroup;
  public auditorContactInformationForm: FormGroup;
  private statusSelect = null;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private toastr: ToastrService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.archivistContactInformationForm = this.fb.group({
      archivistsNames: [null],
      emailArchivist: [null],
      facebookURL: [null],
      twitterHandle: [null],
      instagramHandle: [null],
      linkedInProfile: [null], 
      tikTokProfile: [null],
    });

    this.auditorContactInformationForm = this.fb.group({
      auditorName: [null],
      auditorEmails: [null]
    });

    this.setArchivistContactInformationValidators();
    this.setAuditorContactInformationValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getArchivistContactInformation(this.interviewID).subscribe(information => {
        if (information) {
          this.archivistContactInformationForm = this.fb.group({
            archivistsNames: [information.archivistsNames],
            emailArchivist: [information.emailArchivist ],
            facebookURL: [information.facebookURL ],
            twitterHandle: [information.twitterHandle ],
            instagramHandle: [information.instagramHandle ],
            linkedInProfile: [information.linkedInProfile ],
            tikTokProfile: [information.tikTokProfile ],
          });

          this.setArchivistContactInformationValidators();
        }
      });

      this.backendService.getArchivistForm(+this.interviewID)
      .pipe(
        switchMap(data => {
          if (data) {
            this.statusSelect = data.statusSelect;
          }

          return this.backendService.getAuditorContactInformation(this.interviewID)
        })
      )
      .subscribe(information => {
        if (information) {
          this.auditorContactInformationForm = this.fb.group({
            auditorName: [information.auditorName],
            auditorEmails: [information.auditorEmails]
          });
        }

        this.setAuditorContactInformationValidators();
      });
    }
  }

  setAuditorContactInformationValidators() {
    if (this.statusSelect === 'Auditing In Progress' && this.authService.getUserType() === 'archivist') {
      this.auditorContactInformationForm.controls['auditorName'].setValidators(Validators.required);
      this.auditorContactInformationForm.controls['auditorName'].updateValueAndValidity();
      this.auditorContactInformationForm.controls['auditorEmails'].setValidators(Validators.required);
      this.auditorContactInformationForm.controls['auditorEmails'].updateValueAndValidity();
    }
  }

  setArchivistContactInformationValidators() {
    if (this.authService.getUserType() === 'archivist') {
      this.archivistContactInformationForm.controls['archivistsNames'].setValidators(Validators.required);
      this.archivistContactInformationForm.controls['archivistsNames'].updateValueAndValidity();
      this.archivistContactInformationForm.controls['emailArchivist'].setValidators(Validators.required);
      this.archivistContactInformationForm.controls['emailArchivist'].updateValueAndValidity();
    }
  }


  saveForm() {
    const archivistsNames = this.archivistContactInformationForm.controls['archivistsNames'].value;
    const emailArchivist = this.archivistContactInformationForm.controls['emailArchivist'].value;
    const facebookURL = this.archivistContactInformationForm.controls['facebookURL'].value;
    const twitterHandle = this.archivistContactInformationForm.controls['twitterHandle'].value;
    const instagramHandle = this.archivistContactInformationForm.controls['instagramHandle'].value;
    const linkedInProfile = this.archivistContactInformationForm.controls['linkedInProfile'].value;
    const tikTokProfile = this.archivistContactInformationForm.controls['tikTokProfile'].value;
    const interviewID = this.interviewID;
    let data = {
      archivistsNames,
      interviewID,
      emailArchivist,
      facebookURL,
      twitterHandle,
      instagramHandle,
      linkedInProfile,
      tikTokProfile
    };
    this.backendService.saveArchivistContactInformation(data)
      .subscribe(() => this.toastr.success('Archivist Contact Information', 'Data saved successfully'))
    this.formSaved.emit();
  }

  saveAuditorContactInformationForm() {
    if (!this.auditorContactInformationForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const auditorName = this.auditorContactInformationForm.controls['auditorName'].value;
    const auditorEmails = this.auditorContactInformationForm.controls['auditorEmails'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      auditorName,
      auditorEmails
    };
    this.backendService.saveAuditorContactInformation(data)
      .subscribe(() => this.toastr.success('Auditor Contact Information', 'Data saved successfully'))
    this.formSaved.emit();
  }
}
