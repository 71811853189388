import { Component, ElementRef, EventEmitter, Input, OnInit, Output,SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { UploadxOptions, UploadState, UploadxService, Uploader, UploadxControlEvent } from 'ngx-uploadx';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilKeyChanged, map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ufile } from '../core/forms/other-files/ufile';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  @Input() fileType: string;

  @ViewChild('myInput')
  myInputVariable: ElementRef;

  files = [];

  fileToUpload: File = null;

  control!: UploadxControlEvent;
  state!: UploadState;
  uploads: Ufile[] = [];
  state$: Observable<UploadState>;
  uploads$: Observable<Uploader[]>;
  options: UploadxOptions = {
    endpoint: 'https://archive.1947partitionarchive.org/upload',
    authorize: (req, token) => {
      if (environment.production && req.url.includes('localhost')) {
        req.url = req.url.replace('localhost:3000', 'archive.1947partitionarchive.org');
      } else if (!environment.production && req.url.includes('https://localhost')) {
        req.url = req.url.replace('https://localhost', 'http://localhost');
      } else if (!environment.production && req.url.includes('archive.1947partitionarchive.org')) {
        req.url = req.url.replace('https://archive.1947partitionarchive.org', 'http://localhost:3000');
      }
      token && (req.headers.Authorization = token);
      return req;
    },
    multiple: true,
    maxChunkSize: 1024 * 1024 * 1
  };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private uploadService: UploadxService,
    private authService: AuthService,
    private backendService: BackendService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.options.token = this.authService.getToken();
    this.uploads$ = this.uploadService.connect(this.options);
    this.state$ = this.uploadService.events;

    let fileUploadCounter = 0;

    this.state$
    .pipe(
      takeUntil(this.destroyed$),
      distinctUntilKeyChanged('status')
    )
    .subscribe(state => {
      if (state.status === 'complete') {
        fileUploadCounter = fileUploadCounter + 1;

        this.toastr.success('File has been uploaded and is being processed.');

        this.backendService.saveFile(this.interviewID, state.file.name).subscribe(data => {
          if (data.error) {
            alert(data.error);
          }
          this.loadFiles();

          if (fileUploadCounter === this.uploads.length) {
            this.myInputVariable.nativeElement.value = "";
            fileUploadCounter = 0;
            this.uploads = [];

            this.uploadService.disconnect();
            this.uploads$ = this.uploadService.connect(this.options);
          }
        }, error => {
            console.log(error);
        });
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.loadFiles();
    }
  }

  get userType() {
    return this.authService.getUserType();
  }

  loadFiles() {
    this.backendService.getFiles(this.interviewID)
    .pipe(
      map(files => {
        if (this.fileType === 'photo') {
          return files.filter(file => file.filename.toLowerCase().includes('_photo'));
        } else if (this.fileType === 'release') {
          return files.filter(file => file.filename.toLowerCase().includes('_release'));
        } else if (this.fileType === 'other') {
          return files.filter(file => !file.filename.toLowerCase().includes('_release') && !file.filename.toLowerCase().includes('_audio') && !file.filename.toLowerCase().includes('_photo') && !file.filename.toLowerCase().includes('_video') &&!file.filename.toLowerCase().includes('_broll'));
        } else if (this.fileType === 'audio-video') {
          return files.filter(file => file.filename.toLowerCase().includes('_video') || file.filename.toLowerCase().includes('_audio') || file.filename.toLowerCase().includes('_broll'));
        }
      })
    )
    .subscribe(data => {
      if (data) {
        this.files = data;

        // Parse filenames
        this.files.map(file => {
          if (file.filename.startsWith('T_')) {
            file.prefix = 'T_';
            file.displayName = file.filename.substring(2);
          } else if (file.filename.startsWith('PA_')) {
            file.prefix = 'PA_';
            file.displayName = file.filename.substring(3);
          } else {
            file.prefix = null;
            file.displayName = file.filename;
          }
          file.fileType = this.fileType;


          return file;
        })
      }
    });
  }

  delete(filename: string) {
    this.backendService.delete(filename).subscribe(data => {
      this.loadFiles();
    });
  }

  archive(filename: string) {
    this.backendService.archive(filename).subscribe(data => {
      this.loadFiles();
    });
  }



  viewPhoto(src: string, vidName: string) {
    document.getElementById('div_video').innerHTML = '<img style="width: 80%; text-align: center; " src="'+src+'" /><div style="width: 80%; text-align: center; " >Viewing: "'+vidName+'" </div>';
  }

  setvideo(src: string, vidName: string) {
    document.getElementById('div_video').innerHTML = '<video autoplay controls style="width: 80%; text-align: center; " id="video_ctrl" ><source src="'+src+'" type="video/mp4"></video><div style="width: 80%; text-align: center; " >Now Playing: "'+vidName+'" </div>';
  }

  rename(originalFilename: string, newFilename: string, location: string) {
    // Add in prefixes if they were there
    if (originalFilename.startsWith('T_')) {
      newFilename = 'T_' + newFilename;
    } else if (originalFilename.startsWith('PA')) {
      newFilename = 'PA_' + newFilename;
    }

    this.backendService.rename(originalFilename, newFilename, location).subscribe(data => {
      this.loadFiles();
      this.toastr.success('File renamed successfully.')
    });
  }

  upload(uploadId?: string): void {
    this.control = { action: 'upload', uploadId };
  }

  download(url: string, filename: string): void {
    window.open(url, '_blank').focus();
    //this.backendService.exportURL(url, filename).subscribe();
  }

  onStateChanged(evt: UploadState): void {
    this.state = evt;
    const file = this.uploads.find(item => item.uploadId === evt.uploadId);
    file ? file.update(evt) : this.uploads.push(new Ufile(evt));
  }

  ngOnDestroy(): void {
    this.uploadService.disconnect();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
