
<div class="form-title mt-3 mb-5">
    Interview Photo Files
    <div class="mt-3" style="text-align:left;font-size:14px;margin:0 50px">
      <span style="display:inline-block;width:20px"></span>Write a detailed caption for each photo: when was the photo taken? Who or what is represented in the photo?
      <br><br>
      <span style="text-decoration:underline">File Naming Convention:</span>
      <br><br>
      <b>Photos:</b> Firstname_Middlename_Lastname_mm-dd-yyyy_PHOTO_#of#
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_PHOTO_1of5
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_PHOTO_2of5
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_PHOTO_3of5
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_PHOTO_4of5
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_PHOTO_5of5
    </div>

    <div id="div_video"> </div>
</div>

<app-file-uploader [fileType]="fileType" [interviewID]="interviewID"></app-file-uploader>

<div class="form-data" [formGroup]="photoCaptionsForm">
  <div class="form-group">
    <label for="photo1Caption" class="required">Photo #1 caption</label>
    <input type="text" class="form-input" id="photo1Caption"  formControlName="photo1Caption">
  </div>
  <div class="form-group">
    <label for="photo2Caption" class="required">Photo #2 caption</label>
    <input type="text" class="form-input" id="photo2Caption" formControlName="photo2Caption">
  </div>
  <div class="form-group">
    <label for="photo3Caption" class="required">Photo #3 caption</label>
    <input type="text" class="form-input" id="photo3Caption" formControlName="photo3Caption">
  </div>
  <div class="form-group">
    <label for="photo4Caption" class="required">Photo #4 caption</label>
    <input type="text" class="form-input" id="photo4Caption" formControlName="photo4Caption">
  </div>
  <div class="form-group">
    <label for="photo5Caption" class="required">Photo #5 caption</label>
    <input type="text" class="form-input" id="photo5Caption" formControlName="photo5Caption">
  </div>
  <div class="form-group">
    <label for="photo6Caption">Photo #6 caption</label>
    <input type="text" class="form-input" id="photo6Caption" formControlName="photo6Caption">
  </div>
  <div class="form-group">
    <label for="photo7Caption">Photo #7 caption</label>
    <input type="text" class="form-input" id="photo7Caption" formControlName="photo7Caption">
  </div>
  <div class="form-group">
    <label for="photo8Caption">Photo #8 caption</label>
    <input type="text" class="form-input" id="photo8Caption" formControlName="photo8Caption">
  </div>
  <div class="form-group">
    <label for="photo9Caption">Photo #9 caption</label>
    <input type="text" class="form-input" id="photo9Caption" formControlName="photo9Caption">
  </div>
  <div class="form-group">
    <label for="photo10Caption">Photo #10 caption</label>
    <input type="text" class="form-input" id="photo10Caption" formControlName="photo10Caption">
  </div>
  <div class="form-group">
    <label for="photo11Caption">Photo #11 caption</label>
    <input type="text" class="form-input" id="photo11Caption" formControlName="photo11Caption">
  </div>
  <div class="form-group">
    <label for="photo12Caption">Photo #12 caption</label>
    <input type="text" class="form-input" id="photo12Caption" formControlName="photo12Caption">
  </div>
  <div class="form-group">
    <label for="additionalCaptions">Additional photo captions</label>
    <textarea id="additionalCaptions" class="w-100 form-textarea" formControlName="additionalCaptions" (paste)="onPaste($event, 'additionalCaptions')"></textarea>
  </div>
  <div class="form-group">
    <label for="olderPhotosAmount">Number of Vintage Black and White Photos</label>
    <input type="text" class="form-input" id="olderPhotosAmount" formControlName="olderPhotosAmount">
  </div>
  <div class="form-group" *ngIf="userType !== 'archivist'">
    <label for="originalPhotoFormat">Original photo format</label>
    <input type="text" class="form-input" id="originalPhotoFormat" formControlName="originalPhotoFormat">
  </div>
</div>

<div class="mt-5 text-center">
    <button class="save-button" style="width:190px;outline:none" (click)="saveForm()">Save</button>
</div>
