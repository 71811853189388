import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-pseudonym',
  templateUrl: './pseudonym.component.html',
  styleUrls: ['./pseudonym.component.scss']
})
export class PseudonymComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public pseudonymForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.pseudonymForm = this.fb.group({
      pseudonym: [null]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getPseudonym(this.interviewID).subscribe(information => {
        if (information) {
          this.pseudonymForm = this.fb.group({
            pseudonym: [ information.pseudonym ]
          });
        }
      });
    }
  }

  saveForm() {
    const pseudonym = this.pseudonymForm.controls['pseudonym'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID,
      pseudonym
    };
    this.backendService.savePseudonym(data)
      .subscribe(() => this.toastr.success('Pseudonym', 'Data saved successfully'));
    this.formSaved.emit();
  }

}
